/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  sendApproval,
  submitZohoApproval,
} from "../redux/actions/APmoduleAction";
import { Spin } from "antd";
import {
  UPLOAD_AP_INVOICE_RESET,
  UPLOAD_SEND_RESET,
  UPLOAD_SUBMIT_ZOHO_RESET,
} from "../redux/constants/APmoduleConstant";
import { CheckZohoOrgId } from "../settings/CrmandErpConnect/CrmandErpAction";

const schema = yup.object({
  user: yup
    .object()
    .shape({
      value: yup.string().required("Requested to is required"),
    })
    .nullable()
    .required("Requested to is required"),
});

const ApprovalModal = ({
  requestedToOptions,
  handleRequestToChange,
  requestedTo,
  setRequestedTo,
  apInvoiceData,
  zohoApprovalLoading,
  zohoApprovalSuccess,
  apiSubmitZohoError,
  setApiSubmitZohoError,
  apiSendError,
  setApiSendError,
  sendApprovalSuccess,
  sendApprovalLoading,
  messageApi,
  handleModalClose,
  listApiCall,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userSignin);
  const { userId } = userData;
  const selectRefs = useRef({});
  const isOrgId = useSelector((state) => state.toCheckZohoOrgId);
  const { checkOrgId } = isOrgId;


  const {
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { user: requestedTo },
  });

  const handleSend = (data) => {
    const formData = {
      ap_invoice: parseInt(apInvoiceData?.id),
      requested_to: parseInt(data.user.value),
      requested_by: userId,
    };
    dispatch(sendApproval(formData));
  };

  const handleSubmitZoho = () => {
    dispatch(submitZohoApproval(apInvoiceData?.id));
  };

  useEffect(() => {
    if (zohoApprovalSuccess || sendApprovalSuccess) {
      setApiSubmitZohoError({});
      setApiSendError({});
      handleModalClose("#send-approval");
      setRequestedTo({ user: null });
      dispatch({ type: UPLOAD_SEND_RESET });
      dispatch({ type: UPLOAD_SUBMIT_ZOHO_RESET });
      dispatch(listApiCall);
      messageApi.open({
        type: "success",
        content: zohoApprovalSuccess ? "Submitted to Zoho" : "Approval Sent",
        duration: 5,
      });
    }
  }, [zohoApprovalSuccess, sendApprovalSuccess]);

  useEffect(() => {
    setValue("user", requestedTo.user);
  }, [requestedTo, setValue]);

  const onModalClose = () => {
    $("#send-approval").on("hide.bs.modal", () => {
      clearErrors("user");
      setRequestedTo({ user: null });
      setApiSubmitZohoError({});
      setApiSendError({});
      dispatch({ type: UPLOAD_AP_INVOICE_RESET });
    });
  };

  useEffect(() => {
    onModalClose();
  }, []);
  useEffect(() => {
    dispatch(CheckZohoOrgId());
  }, []);
  return (
    <div
      id="send-approval"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {apInvoiceData?.approval_status !== "user approved"
                ? "Sending for Approval"
                : checkOrgId.exist
                ? "Submit to Zoho"
                : ""}
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <form onSubmit={handleSubmit(handleSend)}>
            <div className="modal-body p-4">
              <div className="row">
                {apiSubmitZohoError?.detail && (
                  <p className="text-danger">{apiSubmitZohoError?.detail}</p>
                )}
                {!checkOrgId.exist &&
                  apInvoiceData?.approval_status === "user approved" && (
                    <p className="warning" style={{ color: "" }}>
                      You haven't connected to any third-party ERP yet!
                    </p>
                  )}
                {apInvoiceData?.approval_status !== "user approved" && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="requestedTo" className="form-label">
                        Request to
                      </label>
                      <Controller
                        name="user"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            ref={(el) => (selectRefs.current["user"] = el)}
                            options={requestedToOptions?.results?.map(
                              (option) => ({
                                value: option.id,
                                label: `${option?.first_name} ${option?.last_name}`,
                              })
                            )}
                            onChange={(selectedOption) => {
                              handleRequestToChange(selectedOption, "user");
                              field.onChange(selectedOption);
                            }}
                          />
                        )}
                      />
                      {apiSubmitZohoError?.detail && (
                        <p className="text-danger">
                          {apiSubmitZohoError?.detail}
                        </p>
                      )}
                      {errors?.user?.message && (
                        <p className="text-danger">{errors?.user?.message}</p>
                      )}
                      {apiSendError?.requested_to && (
                        <p className="text-danger">
                          {apiSendError?.requested_to}
                        </p>
                      )}
                      {apiSendError?.non_field_errors && (
                        <p className="text-danger">
                          {apiSendError?.non_field_errors}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light me-1"
                onClick={() => handleModalClose("#send-approval")}
              >
                Close
              </button>
              {checkOrgId.exist && (
                <button
                  type="button"
                  className={
                    apInvoiceData?.approval_status !== "user approved"
                      ? "btn btn-light me-1"
                      : "btn btn-primary waves-effect waves-light"
                  }
                  onClick={handleSubmitZoho}
                  disabled={
                    zohoApprovalLoading ||
                    apInvoiceData?.approval_status === "pending approval"
                  }
                >
                  {zohoApprovalLoading ? <Spin /> : "Submit to Zoho"}
                </button>
              )}

              {apInvoiceData?.approval_status !== "user approved" && (
                <button
                  type="submit"
                  className={
                    apInvoiceData?.approval_status === "user approved"
                      ? "btn btn-light me-1"
                      : "btn btn-primary waves-effect waves-light"
                  }
                  disabled={
                    sendApprovalLoading ||
                    apInvoiceData?.approval_status === "user approved"
                  }
                >
                  {sendApprovalLoading ? <Spin /> : "Send"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ApprovalModal.propTypes = {
  requestedToOptions: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired,
      })
    ),
  }),
  handleRequestToChange: PropTypes.func.isRequired,
  apInvoiceData: PropTypes.object.isRequired,
  zohoApprovalLoading: PropTypes.bool.isRequired,
  zohoApprovalSuccess: PropTypes.bool.isRequired,
  apiSubmitZohoError: PropTypes.object.isRequired,
  setApiSubmitZohoError: PropTypes.func.isRequired,
  apiSendError: PropTypes.object.isRequired,
  setApiSendError: PropTypes.func.isRequired,
  sendApprovalSuccess: PropTypes.bool.isRequired,
  sendApprovalLoading: PropTypes.bool.isRequired,
  messageApi: PropTypes.object.isRequired,
  listApiCall: PropTypes.func,
};

export default ApprovalModal;
