/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../_components/antd.css";
import { message, Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Select from "react-select";
import DatePicker from "react-datepicker";

import {
  TransactionSubmitToZohoApi,
  transactionViewApi,
} from "../redux/actions/BankModuleAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
  formatDateYYMMDD,
} from "../sales/invoices/constant";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SubmitToErpModal from "../sales/invoices/submitToErpModal";
import { TRANSACTION_SUBMIT_TO_ZOHO_RESET } from "../redux/constants/BankConstant";

const AllTransaction = ({ activeTab, page, perPage, setPage }) => {
  const [filterData, setFilterData] = useState({
    account_name: "",
    date: "",
    party_name: "",
  });
  const [submitToZohoDetail, setSubmitToZohoDetail] = useState({});
  const [submitToZohoError, setSubmitToZohoError] = useState("");
  const [show, setShow] = useState(false);

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const transactionDetail = useSelector((state) => state?.transactionDetail);
  const {
    transactionViewDetail,
    loading: transactionViewLoading,
  } = transactionDetail;

  const { id } = useParams();

  const transactionSubmitToZoho = useSelector(
    (state) => state?.transactionSubmitToZoho
  );
  const { loading, success, error } = transactionSubmitToZoho;

  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const handleEditClick = (data) => {
    setSubmitToZohoDetail(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      account_id: id,
      transaction_ids: [submitToZohoDetail?.id],
    };
    dispatch(TransactionSubmitToZohoApi(data));
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  const onModalClose = () => {
    $("#submit_to_erp").on("hide.bs.modal", () => {
      setSubmitToZohoDetail({});
      setSubmitToZohoError("");
      dispatch({ type: TRANSACTION_SUBMIT_TO_ZOHO_RESET });
    });
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  useEffect(() => {
    if (error) {
      setSubmitToZohoError(error);
    }
  }, [error]);

  useEffect(() => {
    onModalClose();
  }, []);

  const transactionColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Bank Narration",
      align: "center",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      sorter: (a, b) =>
        a?.transaction_type?.length - b?.transaction_type?.length,
    },
    {
      title: "Ref No.",
      dataIndex: "ref_number",
      sorter: (a, b) => a?.ref_number?.length - b?.ref_number?.length,
    },
    {
      title: "Dr",
      dataIndex: "debit_amount",
      sorter: (a, b) => a?.debit_amount?.length - b?.debit_amount?.length,
    },
    {
      title: "Cr",
      dataIndex: "credit_amount",
      sorter: (a, b) => a?.credit_amount?.length - b?.credit_amount?.length,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      sorter: (a, b) => a?.balance?.length - b?.balance?.length,
    },
    {
      title: "Party/Ledger",
      dataIndex: "party_name",
      sorter: (a, b) => a?.party_name?.length - b?.party_name?.length,
    },
    {
      title: "Posting",
      dataIndex: "posting",
      render: (text, record) => (
        <div>{record?.posting ? "Posted" : "Unposted"}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          <Link
            to={`/all-transaction/edit/${id}/${record?.id}`}
            className="btn btn-primary me-2"
          >
            <i className="far fa-edit" />
          </Link>
          &nbsp;
          <Link
            className="btn btn-greys"
            data-bs-toggle="modal"
            to="#submit_to_erp"
            role="button"
            onClick={() => handleEditClick(record)}
          >
            <i className="far fa-send" />
          </Link>
        </>
      ),
    },
  ];

  const handleTableChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    setPage({ page: 1, perPage: 10 });
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDateFilterChange = (selectedDate, id) => {
    setPage({ page: 1, perPage: 10 });
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
  };

  useEffect(() => {
    if (success) {
      handleModalClose("#submit_to_erp");
      closeModal();
      setSubmitToZohoDetail({});
      setSubmitToZohoError("");
      dispatch({ type: TRANSACTION_SUBMIT_TO_ZOHO_RESET });
      messageApi.open({
        type: "success",
        content: "Submitted to Zoho",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    if (id && activeTab?.includes("#alltransanction")) {
      dispatch(
        transactionViewApi(
          page,
          perPage,
          id,
          "",
          filterData?.party_name,
          filterData?.date ? formatDateYYMMDD(filterData?.date) : "",
          filterData?.account_name
        )
      );
    }
  }, [id, page, perPage, activeTab, filterData]);

  return (
    <>
      {contextHolder}
      {/* <div className="list-btn">
        <ul className="filter-list filterbutton apmoduleHeader">
          <li>
            <Link
              className="btn btn-primary  me-2 "
              to={`/add-transactions/${id}`}
              role="button"
            >
              <i className="fa fa-plus-circle me-2" aria-hidden="true" />
              Create Transaction
            </Link>
          </li>
        </ul>
      </div> */}
      <div
        className="list-btn"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <ul
          className="filter-list"
          style={{
            display: "flex",
            flex: 1,
            listStyle: "none",
            padding: 0,
            margin: 0,
            alignItems: "center",
          }}
        >
          <li>
            <input
              id="party_name"
              type="text"
              value={filterData?.party_name}
              onChange={handleFilterChange}
              placeholder="Party Name"
              className={"form-control"}
            />
          </li>
          <div
            style={containerStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <li>
              <DatePicker
                className="datetimepicker form-control"
                id="date"
                placeholderText="Date"
                selected={filterData?.date}
                onChange={(selectedDate) =>
                  handleDateFilterChange(selectedDate, "date")
                }
              />
              {filterData?.date && (
                <button
                  type="button"
                  style={{
                    ...clearButtonStyle,
                    ...(isHovered ? clearButtonHoverStyle : {}),
                  }}
                  onClick={() => handleDateFilterChange(null, "date")}
                  aria-label="Clear Date"
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </li>
          </div>
          <li>
            <input
              id="account_name"
              type="text"
              value={filterData?.account_name}
              onChange={handleFilterChange}
              placeholder="Account Name"
              className={"form-control"}
            />
          </li>
          <li style={{ marginLeft: "auto" }}>
            <Link
              className="btn btn-primary  me-2 "
              to={`/add-transactions/${id}`}
              role="button"
            >
              <i className="fa fa-plus-circle me-2" aria-hidden="true" />
              Create Transaction
            </Link>
          </li>
          {/* <li>
            <Select
              // options={formattedOptions(intervalList)}
              placeholder={"Bank"}
            />
          </li> */}
          {/* <li>
          <Select
            // options={formattedOptions(dayList)}
            placeholder={"Transaction"}
          />
        </li> */}
        </ul>
        {/* <li style={{ listStyle: 'none', marginLeft: 'auto' }}>
        <Select
          // options={formattedOptions(dayList)}
          placeholder={"Post to ERP"}
        />
      </li> */}
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body payments">
              <div className="table-responsive ">
                <Table
                  loading={transactionViewLoading}
                  pagination={{
                    total: transactionViewDetail?.count,
                    current: page,
                    pageSize: perPage,
                    onChange: handleTableChange,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    // showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={transactionColumns}
                  dataSource={transactionViewDetail?.results}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubmitToErpModal
        show={show}
        submitToErpError={submitToZohoError}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};

export default AllTransaction;
