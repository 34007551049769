import  { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import { clearError } from './actions/commonApiAction';

const ErrorToast = () => {
  const errorMessage = useSelector(state => state.error.errorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      const hide = message.error({
        content: errorMessage,
        duration: 3, // Display for 3 seconds
        onClose: () => {
          dispatch(clearError());
        }
      });

      // Ensure error is cleared from Redux store even if user manually closes the message
      return () => {
        hide();
        dispatch(clearError());
      };
    }
  }, [errorMessage, dispatch]);

  return null; // This component doesn't render anything
};

export default ErrorToast;