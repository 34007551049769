/* eslint-disable */
import { Spin } from "antd";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CustomInput from "../../../_components/inputField/custominput";
import { Link } from "react-router-dom";
import CustomSelect from "../../../customers/customSelect";

const CustomerModal = ({
  setAddCustomer,
  addCustomer,
  setCustomerApiError,
  customerApiError,
  handleModalClose,
  handleEditSubmit,
  loading,
  isDisable,
  setIsDisable,
}) => {
  const handleChange = (e) => {
    const { id, value } = e.target;
    setCustomerApiError((prevState) => ({ ...prevState, [id]: "" }));
    setAddCustomer((prevState) => ({ ...prevState, [id]: value }));
    setIsDisable(true);
  };

  const onModalOpen = () => {
    $("#customer_modal").on("shown.bs.modal", () => {
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      setCustomerApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
    });
    setCustomerApiError({});
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  const createCustomer = (
    <div
      id="customer_modal"
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "1050",
        }}
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h4 className="modal-title">Add Customer</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="form-group-item">
                    {/* <form onSubmit={handleSubmit(handleEditSubmit)}> */}
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="name"
                            type="text"
                            value={addCustomer?.name}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Name"
                            label="Name"
                            // errorMsg={errors?.name?.message}
                            // error={errors?.name}
                            apiError={customerApiError?.name}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="contact_name"
                            type="text"
                            value={addCustomer?.contact_name}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Contact Name"
                            label="Contact Name"
                            // errorMsg={errors?.contact_name?.message}
                            // error={errors?.contact_name}
                            apiError={customerApiError?.contact_name}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="location"
                            type="text"
                            value={addCustomer?.location}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Location"
                            label="Location"
                            // errorMsg={errors?.location?.message}
                            // error={errors?.location}
                            apiError={customerApiError?.location}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="customer_code"
                            type="text"
                            value={addCustomer?.customer_code}
                            onChange={handleChange}
                            placeholder="Customer Code"
                            label="Customer Code"
                            apiError={customerApiError?.customer_code}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="email"
                            type="text"
                            value={addCustomer?.email}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Email"
                            label="Email"
                            // errorMsg={errors?.email?.message}
                            // error={errors?.email}
                            apiError={customerApiError?.email}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="gstin_number"
                            type="text"
                            value={addCustomer?.gstin_number}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Enter GST number"
                            label="GST no"
                            // errorMsg={errors?.gstin_number?.message}
                            // error={errors?.gstin_number}
                            apiError={customerApiError?.gstin_number}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="pan_number"
                            type="text"
                            value={addCustomer?.pan_number}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Enter PAN number"
                            label="PAN no"
                            // errorMsg={errors?.pan_number?.message}
                            // error={errors?.pan_number}
                            apiError={customerApiError?.pan_number}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="state"
                            type="text"
                            value={addCustomer?.state}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Enter State"
                            label="State"
                            // errorMsg={errors?.state?.message}
                            // error={errors?.state}
                            apiError={customerApiError?.state}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="phone"
                            type="text"
                            value={addCustomer?.phone}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Enter phone number"
                            label="Phone no"
                            // errorMsg={errors?.phone?.message}
                            // error={errors?.phone}
                            apiError={customerApiError?.phone}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="address"
                            type="text"
                            value={addCustomer?.address}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Enter address"
                            label="Address"
                            // errorMsg={errors?.address?.message}
                            // error={errors?.address}
                            apiError={customerApiError?.address}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id="zip_code"
                            type="text"
                            value={addCustomer?.zip_code}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder="Enter zipcode number"
                            label="Zipcode"
                            // errorMsg={errors?.zip_code?.message}
                            // error={errors?.zip_code}
                            apiError={customerApiError?.zip_code}
                            autoComplete="false"
                            required={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="add-customer-btns text-end">
                      <Link to="/chartofaccount">
                        <button
                          type="reset"
                          className="btn btn-primary cancel me-2"
                          onClick={() => handleModalClose("#customer_modal")}
                        >
                          Cancel
                        </button>
                      </Link>
                      <button
                        // type="submit"
                        className="btn btn-primary"
                        onClick={handleEditSubmit}
                        // disabled={!isChanged}
                      >
                        {loading ? <Spin /> : "Save"}
                      </button>
                    </div>
                    {/* )} */}
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{createCustomer}</>;
};

CustomerModal.propTypes = {
  addLedgerData: PropTypes.object,
  apiError: PropTypes.object,
  handleChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  loading: PropTypes.bool,
  handleSelectChange: PropTypes.func,
};

export default CustomerModal;
