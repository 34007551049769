import { ORGANIZATION_FAIL, ORGANIZATION_RESET, ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION_REQUEST } from "../constants/OrganizationConstant";

export const organizationEditReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_ORGANIZATION_REQUEST:
        return { loading: true };
  
      case ORGANIZATION_SUCCESS:
        return { loading: false, success: true };
  
      case ORGANIZATION_FAIL:
        return { loading: false, error: action.payload };
      case ORGANIZATION_RESET:
        return {};
      default:
        return state;
    }
  };