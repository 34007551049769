import { HTTP } from "../../src/redux/api";
import {
  GET_SUBSCRIPTION_HISTORY_LIST_FAIL,
  GET_SUBSCRIPTION_HISTORY_LIST_REQUEST,
  GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS,
  GET_SUBSCRIPTION_PLANS_LIST_FAIL,
  GET_SUBSCRIPTION_PLANS_LIST_REQUEST,
  GET_SUBSCRIPTION_PLANS_LIST_SUCCESS,
} from "./membershipConstant";

export const getSubscrptionHistoryList = () => async (dispatch, getState) => {
  dispatch({ type: GET_SUBSCRIPTION_HISTORY_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("payment/subscriptions-history/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_HISTORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSubscrptionPlanList = () => async (dispatch, getState) => {
  dispatch({ type: GET_SUBSCRIPTION_PLANS_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("payment/plans/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_SUBSCRIPTION_PLANS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_PLANS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
