/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../_components/antd.css";
import { Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import {
  bankStatementViewApi,
  getBankDropdownList,
} from "../redux/actions/BankModuleAction";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { clearButtonHoverStyle, clearButtonStyle, containerStyle, formatDateYYMMDD } from "../sales/invoices/constant";

export const statusList = [
  { id: "", text: "All" },
  { id: "Pending", text: "Pending" },
  { id: "Inprogress", text: "Inprogress" },
  { id: "Completed", text: "Completed" },
];

const BankStatement = ({ activeTab, page, perPage, setPage }) => {
  const [filterData, setFilterData] = useState({
    bank_name: "",
    account_name: "",
    uploaded_date: "",
    status: "",
  });

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const bankStatement = useSelector((state) => state?.bankStatement);
  const { data, loading } = bankStatement;

  const bankListData = useSelector((state) => state?.bankListData);
  const { bankDropdownList } = bankListData;

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleTableChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    setPage({ page: 1, perPage: 10 });
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDateFilterChange = (selectedDate, id) => {
    setPage({ page: 1, perPage: 10 });
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const columns = [
    {
      title: "Update Date",
      dataIndex: "uploaded_date",
      sorter: (a, b) => a?.uploaded_date?.length - b?.uploaded_date?.length,
    },
    {
      title: "Statement Period",
      align: "center",
      dataIndex: "time_period",
      sorter: (a, b) => a?.time_period?.length - b?.time_period?.length,
    },
    {
      title: "No. of Records",
      dataIndex: "no_of_records",
      sorter: (a, b) => a?.no_of_records?.length - b?.no_of_records?.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.length - b?.status?.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      // sorter: (a, b) => a.actions.length - b.actions.length,
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <a
            href={record?.file ? record?.file : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-eye" />
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (id && activeTab?.includes("#bankstatement")) {
      dispatch(
        bankStatementViewApi(
          page,
          perPage,
          id,
          filterData?.bank_name ? filterData?.bank_name?.value : "",
          filterData?.account_name,
          filterData?.uploaded_date
            ? formatDateYYMMDD(filterData?.uploaded_date)
            : "",
          filterData?.status ? filterData?.status?.value : ""
        )
      );
    }
  }, [id, page, perPage, activeTab, filterData]);

  useEffect(() => {
    dispatch(getBankDropdownList());
  }, []);

  return (
    <>
      <div
        className="list-btn"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <ul
          className="filter-list"
          style={{
            display: "flex",
            flex: 1,
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <li>
            <Select
              value={filterData?.bank_name}
              options={[
                { value: "", label: "All" }, // Adding an empty option
                ...(bankDropdownList?.map((option) => ({
                  value: option.id,
                  label: option.bank_name,
                })) || []), 
              ]}
              placeholder="Select Bank"
              onChange={(selectedOption) => {
                setFilterData((prevState) => ({
                  ...prevState,
                  bank_name: selectedOption,
                }));
              }}
            />
          </li>
          <li>
            <input
              id="account_name"
              type="text"
              placeholder="Account Name"
              value={filterData?.account_name}
              className="form-control"
              onChange={handleFilterChange}
            />
          </li>
            <li className="uploadDate">
              <DatePicker
                className="datetimepicker form-control"
                id="uploaded_date"
                placeholderText="Uploaded Date"
                selected={filterData?.uploaded_date}
                onChange={(selectedDate) =>
                  handleDateFilterChange(selectedDate, "uploaded_date")
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {filterData?.uploaded_date && (
                <button
                  type="button"
                  style={{
                    ...clearButtonStyle,
                    ...(isHovered ? clearButtonHoverStyle : {}),
                  }}
                  onClick={() => handleDateFilterChange(null, "uploaded_date")}
                  aria-label="Clear Date"
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </li>
          <li>
            <Select
              options={formattedOptions(statusList)}
              placeholder={"Select Status"}
              value={filterData?.status}
              onChange={(selectedOption) =>
                setFilterData((prevState) => ({
                  ...prevState,
                  status: selectedOption,
                }))
              }
            />
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className=" card">
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  loading={loading}
                  pagination={{
                    total: data?.count,
                    current: page,
                    pageSize: perPage,
                    onChange: handleTableChange,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    // showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={columns}
                  dataSource={data?.results}
                  rowKey={(record) => record.Id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankStatement;
