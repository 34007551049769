import { HTTP } from "../api";
import {
  AP_INVOICE_APPROVAL_FAIL,
  AP_INVOICE_APPROVAL_REQUEST,
  AP_INVOICE_APPROVAL_SUCCESS,
  AP_INVOICE_EDIT_DETAIL_FAIL,
  AP_INVOICE_EDIT_DETAIL_REQUEST,
  AP_INVOICE_EDIT_DETAIL_SUCCESS,
  AP_INVOICE_EDIT_FAIL,
  AP_INVOICE_EDIT_REQUEST,
  AP_INVOICE_EDIT_SUCCESS,
  AP_INVOICE_POST_APPROVAL_REQUEST,
  AP_INVOICE_POST_APPROVAL_SUCCESS,
  AP_INVOICE_POST_APPROVAL_FAIL,
  GET_AP_INVOICELIST_FAIL,
  GET_AP_INVOICELIST_PAGE,
  GET_AP_INVOICELIST_REQUEST,
  GET_AP_INVOICELIST_SUCCESS,
  GET_MANAGER_LIST_FAIL,
  GET_MANAGER_LIST_REQUEST,
  GET_MANAGER_LIST_SUCCESS,
  GET_TDS_RATE_LIST_FAIL,
  GET_TDS_RATE_LIST_REQUEST,
  GET_TDS_RATE_LIST_SUCCESS,
  UPLOAD_AP_INVOICE_FAIL,
  UPLOAD_AP_INVOICE_REQUEST,
  UPLOAD_AP_INVOICE_SUCCESS,
  UPLOAD_SEND_FAIL,
  UPLOAD_SEND_REQUEST,
  UPLOAD_SEND_SUCCESS,
  UPLOAD_SUBMIT_ZOHO_FAIL,
  UPLOAD_SUBMIT_ZOHO_REQUEST,
  UPLOAD_SUBMIT_ZOHO_SUCCESS,
  GET_TDS_CALCULATION_REQUEST,
  GET_TDS_CALCULATION_SUCCESS,
  GET_TDS_CALCULATION_FAIL,
  AP_INVOICE_CREATE_REQUEST,
  AP_INVOICE_CREATE_SUCCESS,
  AP_INVOICE_CREATE_FAIL,
} from "../constants/APmoduleConstant";

export const setPage = (page, perPage) => ({
  type: GET_AP_INVOICELIST_PAGE,
  payload: page,
  perPage,
});
export const getApInvoiceList = (
  page = 1,
  perPage = 10,
  start_date,
  end_date,
  user,
  status,
  approval_status
) => async (dispatch, getState) => {
  dispatch({ type: GET_AP_INVOICELIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();

  // Create a query parameters object and filter out undefined values
  const queryParams = {
    page,
    per_page: perPage,
    created_on__gte: start_date,
    created_on__lte: end_date,
    created_by: user,
    status,
    approval_status,
  };

  const queryString = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  try {
    const { data } = await HTTP.get(`/invoice/ap-invoices/?${queryString}`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({
      type: GET_AP_INVOICELIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: GET_AP_INVOICELIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const uploadInvoice = (payload) => async (dispatch, getState) => {
  dispatch({ type: UPLOAD_AP_INVOICE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/invoice/upload-ap-invoices/", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: UPLOAD_AP_INVOICE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPLOAD_AP_INVOICE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const apEditInvoiceDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: AP_INVOICE_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/invoice/v2/ap-invoice/${id}`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: AP_INVOICE_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({
        type: AP_INVOICE_EDIT_DETAIL_FAIL,
        payload: error.response && error?.response?.status,
      });
    } else {
      dispatch({
        type: AP_INVOICE_EDIT_DETAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};

export const updateApInvoice = (payload, id) => async (dispatch, getState) => {
  dispatch({ type: AP_INVOICE_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(
      `/invoice/v2/ap-invoice/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: AP_INVOICE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_INVOICE_EDIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getTdsRateList = () => async (dispatch, getState) => {
  dispatch({ type: GET_TDS_RATE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/tds/tds-rate/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_TDS_RATE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_TDS_RATE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//managerlist
export const getManagerList = () => async (dispatch, getState) => {
  dispatch({ type: GET_MANAGER_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/users/managers/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_MANAGER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_MANAGER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//send approval
export const sendApproval = (payload) => async (dispatch, getState) => {
  dispatch({ type: UPLOAD_SEND_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/invoice/invoice-approval/", payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: UPLOAD_SEND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPLOAD_SEND_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//invoice approval status update
export const invoiceApproval = (payload, id) => async (dispatch, getState) => {
  dispatch({ type: AP_INVOICE_APPROVAL_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(
      `/invoice/invoice-approval/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: AP_INVOICE_APPROVAL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_INVOICE_APPROVAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//invoice approval status update if null
export const invoiceApprovalPost = (payload) => async (dispatch, getState) => {
  dispatch({ type: AP_INVOICE_POST_APPROVAL_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(`/invoice/invoice-approval/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: AP_INVOICE_POST_APPROVAL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_INVOICE_POST_APPROVAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//submit zoho
export const submitZohoApproval = (id) => async (dispatch, getState) => {
  dispatch({ type: UPLOAD_SUBMIT_ZOHO_REQUEST, payload: id });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      `/invoice/ap-invoice/${id}/submit/erp/`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: UPLOAD_SUBMIT_ZOHO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPLOAD_SUBMIT_ZOHO_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const tdsCalculationApi = (payload) => async (dispatch, getState) => {
  dispatch({ type: GET_TDS_CALCULATION_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      `/tds/tds-section/calc-tds-amount/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: GET_TDS_CALCULATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_TDS_CALCULATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//create AP-Invoice
export const CreateAPinvoice = (payload) => async (dispatch, getState) => {
  dispatch({ type: AP_INVOICE_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(`/invoice/ap-invoices/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: AP_INVOICE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_INVOICE_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
