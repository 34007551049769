import { UPDATE_ORGANIZATION_REQUEST, ORGANIZATION_SUCCESS, ORGANIZATION_FAIL } from "../constants/OrganizationConstant";
import { HTTP } from "../api";

export const updateOrganizationApi = (payload) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_ORGANIZATION_REQUEST, payload: payload });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await HTTP.put(`/company/`, payload, {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      });
      dispatch({ type: ORGANIZATION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ORGANIZATION_FAIL,
        payload:
          error.response && error?.response?.data
            ? error?.response?.data
            : error.message,
      });
    }
  };
  