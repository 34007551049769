// import axios from "axios";
import {
  GET_USER_ACTIVITY_FAIL,
  GET_USER_ACTIVITY_REQUEST,
  GET_USER_ACTIVITY_SUCCESS,
  UPDATE_OTP_INFO,
  USER_COMPANY_FAIL,
  USER_COMPANY_REQUEST,
  USER_COMPANY_SUCCESS,
  USER_OTP_FAIL,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  TEAM_MEMBER_CREATE_FAIL,
  TEAM_MEMBER_CREATE_REQUEST,
  TEAM_MEMBER_CREATE_SUCCESS,
  TEAM_MEMBER_UPDATE_FAIL,
  TEAM_MEMBER_UPDATE_REQUEST,
  TEAM_MEMBER_UPDATE_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  TEAM_MEMBER_DELETE_REQUEST,
  TEAM_MEMBER_DELETE_SUCCESS,
  TEAM_MEMBER_DELETE_FAIL,
  GET_TEAM_MEMBER_LIST_REQUEST,
  GET_TEAM_MEMBER_LIST_SUCCESS,
  GET_TEAM_MEMBER_LIST_FAIL,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  SIDE_MENU_UPDATE,
  GET_PAYMENT_PLANS_LIST_FAIL,
  GET_PAYMENT_PLANS_LIST_SUCCESS,
  GET_PAYMENT_PLANS_LIST_REQUEST,
} from "../constants/UserConstant";
import { HTTP } from "../api";
import { jwtDecode } from "jwt-decode";
import { permission } from "../permission";

export const register = (credential) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: credential });
  try {
    const { data } = await HTTP.post("/users/signup/", credential);
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateUserInfo = (userInfo) => ({
  type: UPDATE_OTP_INFO,
  payload: userInfo,
});

export const updateSideMenu = (data) => ({
  type: SIDE_MENU_UPDATE,
  payload: data,
});

export const verifyOtp = (credential) => async (dispatch) => {
  dispatch({ type: USER_OTP_REQUEST, payload: credential });
  try {
    const { data } = await HTTP.post("/users/verify-otp/", credential);
    const userData = jwtDecode(data?.access);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: { data, userData } });
    dispatch({ type: USER_OTP_SUCCESS, payload: { data, userData } });
    if (userData?.username) {
      localStorage.setItem("userName", JSON.stringify(userData?.username));
    }
    localStorage.setItem("userInfo", JSON.stringify(data.access));
    localStorage.setItem("userId", JSON.stringify(userData?.user_id));
    if (userData?.role) {
      localStorage.setItem("role", JSON.stringify(userData?.role));
      localStorage.setItem(
        "permissions",
        JSON.stringify(permission?.find((p) => p.type === userData?.role))
      );
    }
    localStorage.setItem("refresh", JSON.stringify(data.refresh));
  } catch (error) {
    dispatch({
      type: USER_OTP_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const companyData = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER_COMPANY_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/users/signup/company-info/", payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: USER_COMPANY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_COMPANY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getPaymentPlanList = () => async (dispatch, getState) => {
  dispatch({ type: GET_PAYMENT_PLANS_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("payment/plans/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_PAYMENT_PLANS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PAYMENT_PLANS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signin = (credential) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: credential });
  try {
    const { data } = await HTTP.post("/users/login/", credential);
    const userData = jwtDecode(data?.access);
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: { data, userData } });
    if (userData?.username) {
      localStorage.setItem("userName", JSON.stringify(userData?.username));
    }
    localStorage.setItem("userInfo", JSON.stringify(data.access));
    localStorage.setItem("userId", JSON.stringify(userData?.user_id));
    if (userData?.role) {
      localStorage.setItem("role", JSON.stringify(userData?.role));
      localStorage.setItem(
        "permissions",
        JSON.stringify(permission?.find((p) => p.type === userData?.role))
      );
    }
    localStorage.setItem("refresh", JSON.stringify(data.refresh));
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const signOut = (refresh) => async (dispatch, getState) => {
  const {
    userSignin: { userInfo },
  } = getState();
  localStorage.clear();
  try {
    await HTTP.post(
      "/users/logout/",
      { refresh },
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: USER_SIGNOUT });
  } catch (error) {
    localStorage.clear();
    window.location.href = "/";
  }
};

export const getUserActivityModuleList = (page, per_page) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_USER_ACTIVITY_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/users/user-activity/?page=${page}&per_page=${per_page}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_USER_ACTIVITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ACTIVITY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTeamMemberListApi = (page, per_page) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_TEAM_MEMBER_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `users/manage-team/?page=${page}&per_page=${per_page}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );

    dispatch({
      type: GET_TEAM_MEMBER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_MEMBER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const teamMemberCreateApi = (payload) => async (dispatch, getState) => {
  dispatch({ type: TEAM_MEMBER_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/users/manage-team/", payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TEAM_MEMBER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_MEMBER_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const teamMemberUpdateApi = (id, payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: TEAM_MEMBER_UPDATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(`/users/manage-team/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TEAM_MEMBER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_MEMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const teamMemberDeleteApi = (id) => async (dispatch, getState) => {
  dispatch({ type: TEAM_MEMBER_DELETE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.delete(`/users/manage-team/${id}/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TEAM_MEMBER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_MEMBER_DELETE_FAIL,
      payload:
        error.response && error.response.data?.message
          ? error.response.data?.message
          : error.message,
    });
  }
};

export const getUserProfile = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_PROFILE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/users/me`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userProfileUpdateApi = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER_PROFILE_UPDATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(`/users/me/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
