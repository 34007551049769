/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import "../../_components/antd.css";
import { Button, message, Spin, Table } from "antd";
import Select from "react-select";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import {
  getGroupList,
  getLedgerListApi,
  getLedgerModuleList,
  ledgerDetailApi,
  postLedgerModuleList,
  updateLedgerModuleList,
} from "../../redux/actions/LedgerModuleAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomInput from "../../_components/inputField/custominput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import {
  POST_LEDGERLIST_RESET,
  UPDATE_LEDGERLIST_RESET,
} from "../../redux/constants/ledgerListModuleConstant";
import GroupCreationModal from "../../components/GroupCreationModal";
import { HTTP } from "../../redux/api";

const ledgerSchema = yup.object({
  title: yup
    .string()
    .required("Title is required")
    .trim(),
});

const LedgerList = ({ activeTab }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOk = (values) => {
    console.log("Form values:", values);
    setModalVisible(false);
  };
  const [page, setPage] = useState({ page: 1, perPage: 10 });

  // const ledgerList = useSelector((state) => state?.ledgerList);
  // const { loading, data } = ledgerList;
  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoadig,
    error,
    success: postLedgerSuccess,
  } = postLedgerList;

  const updateLedger = useSelector((state) => state?.updateLedger);
  const {
    loading: updateLedgerLoading,
    error: updateLedgerError,
    success: updateLedgerSuccess,
  } = updateLedger;

  const ledgerDetail = useSelector((state) => state?.ledgerDetail);
  const { data: ledgerData } = ledgerDetail;

  const getLedgerList = useSelector((state) => state?.getLedgerList);
  const { ledgerListData, loading: ledgerListLoading } = getLedgerList;

  const groupList = useSelector((state) => state?.groupList);
  const { groupData } = groupList;

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [editLedgerData, setEditLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [editApiError, setEditApiError] = useState({});
  const selectRefs = useRef({});

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ledgerSchema),
    defaultValues: addLedgerData ? addLedgerData : editLedgerData,
  });
  // setEditTrigger(true);

  const handleEditClick = (record) => {
    dispatch(ledgerDetailApi(record?.id));
    setEditLedgerData((prevState) => ({ ...prevState, id: record?.id }));
  };

  useEffect(() => {
    if (ledgerData) {
      const ledgerDetail = groupData?.find(
        (el) => el?.id === ledgerData?.ledger_group
      );
      setEditLedgerData((prevState) => ({
        ...prevState,
        title: ledgerData?.title,
        group: ledgerDetail
          ? {
              label: ledgerDetail?.title,
              value: ledgerDetail?.id,
            }
          : null,
        description: ledgerData?.description,
      }));
    }
  }, [ledgerData]);

  useEffect(() => {
    const fieldOrder = ["title", "group", "description"];
    const firstErrorField = fieldOrder.find((field) => errors && errors[field]);
    let inputElement;
    if (firstErrorField === "group") {
      inputElement = selectRefs.current[firstErrorField];
    } else {
      inputElement = document.getElementById(firstErrorField);
    }
    if (inputElement) {
      inputElement.focus();
    }
  }, [errors]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      render: (text, record, index) =>
        page?.perPage * (page?.page - 1) + index + 1,
    },
    {
      title: "Ledger Name",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Group",
      dataIndex: ["ledger_group", "title"],
      sorter: (a, b) =>
        a?.ledger_group?.title?.length - b?.ledger_group?.title?.length, // Sorting based on group.title length
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          <Link
            className="btn btn-primary me-2"
            data-bs-toggle="modal"
            to="#edit_ledger"
            role="button"
            onClick={() => handleEditClick(record)}
          >
            <i className="far fa-edit" />
          </Link>
          {/* <a className="btn btn-greys">
            <i className="far fa-send" />
          </a> */}
        </>
      ),
    },
  ];

  const handleTableChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setAddLedgerData({ title: "", group: null, description: "" });
      reset();
      setApiError({});
    });
    $("#add_group").on("hide.bs.modal", () => {
      setModalVisible(false);
    });
    $("#edit_ledger").on("hide.bs.modal", () => {
      setEditLedgerData({ title: "", group: null, description: "" });
      reset();
      setEditApiError({});
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  useEffect(() => {
    if (error) setApiError(error);
  }, [error]);

  useEffect(() => {
    if (updateLedgerError) {
      setEditApiError(updateLedgerError);
    }
  }, [updateLedgerError]);

  useEffect(() => {
    if (activeTab?.includes("#ledgertab")) {
      // dispatch(getLedgerModuleList(page?.page, page?.perPage, false, ""));
      dispatch(getLedgerListApi(page?.page, page?.perPage));
      dispatch(getGroupList());
    }
  }, [dispatch, page?.page, page?.perPage, activeTab]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setEditLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
    setEditApiError({});
  };

  const handleChange = (e, module) => {
    const { id, value } = e.target;
    if (module === "edit") {
      setEditLedgerData((prevState) => ({ ...prevState, [id]: value }));
      setEditApiError((prevState) => ({ ...prevState, [id]: "" }));
    } else {
      setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
      setApiError((prevState) => ({ ...prevState, [id]: "" }));
    }
  };

  const handleEditSubmit = (datas) => {
    if (datas?.title) {
      const postData = {
        title: datas?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    }
  };

  const handleUpdateSubmit = (datas) => {
    const data = {
      title: datas?.title,
      ledger_group: editLedgerData?.group ? editLedgerData?.group?.value : null,
      description: editLedgerData?.description,
    };
    dispatch(updateLedgerModuleList(data, editLedgerData?.id));
  };

  useEffect(() => {
    if (postLedgerSuccess) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      handleModalClose("#add_ledger");
      setAddLedgerData({
        title: "",
        group: null,
        description: "",
      });
      setApiError({});
      dispatch(getLedgerListApi(page?.page, page?.perPage));
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess]);

  useEffect(() => {
    if (updateLedgerSuccess) {
      dispatch({ type: UPDATE_LEDGERLIST_RESET });
      handleModalClose("#edit_ledger");
      setEditLedgerData({
        title: "",
        group: null,
        description: "",
      });
      setEditApiError({});
      dispatch(getLedgerListApi(page?.page, page?.perPage));
      setPage({ page: 1, perPage: 10 });
      messageApi.open({
        type: "success",
        content: "Ledger Updated Successfully",
        duration: 5,
      });
    }
  }, [updateLedgerSuccess]);

  const handleSelectChange = (id, selectedOption, module) => {
    if (module === "edit") {
      setEditLedgerData((prevState) => ({
        ...prevState,
        [id]: selectedOption,
      }));
      setEditApiError((prevState) => ({ ...prevState, [id]: "" }));
    } else {
      setAddLedgerData((prevState) => ({ ...prevState, [id]: selectedOption }));
      setApiError((prevState) => ({ ...prevState, [id]: "" }));
    }
  };

  useEffect(() => {
    if (editLedgerData) {
      setValue("title", editLedgerData["title"]);
    }
  }, [editLedgerData, setValue]);

  const createLedger = (
    <div
      id="add_ledger"
      className="modal fade"
      // tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Ledger</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div className="col-md-12">
                  <div className="form-group-item border-0 mb-0">
                    <div className="row align-item-center">
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <Controller
                          name="title"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="title"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e, "add");
                              }}
                              placeholder="Title"
                              label="Title"
                              errorMsg={errors?.title?.message}
                              error={errors?.title}
                              apiError={apiError?.title}
                              autoComplete="false"
                              required={true}
                            />
                          )}
                        />
                        <br />
                        {/* <Controller
                          name="type"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="type"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e);
                              }}
                              placeholder="Type"
                              label="Type"
                              errorMsg={errors?.type?.message}
                              error={errors?.type}
                              apiError={apiError?.type}
                              autoComplete="false"
                              required={true}
                            />
                          )}
                        /> */}
                        {/* <CustomInput
                        id="title"
                        type="text"
                        value={addLedgerData?.title}
                        onChange={handleChange}
                        placeholder="Title"
                        label="Title"
                        required={true}
                        apiError={apiError?.title}
                      />
                      <CustomInput
                        id="type"
                        type="text"
                        value={addLedgerData?.type}
                        onChange={handleChange}
                        placeholder="Type"
                        label="Type"
                        required={true}
                        apiError={apiError?.type}
                      /> */}
                        <div className="row align-item-center">
                          <div className="col-lg-12 col-md-6 col-sm-12">
                            <label>Group</label>
                            <Select
                              id="group"
                              ref={(el) => (selectRefs.current["group"] = el)}
                              value={addLedgerData?.group}
                              options={
                                groupData?.length &&
                                groupData?.map((option) => ({
                                  value: option.id,
                                  label: option.title,
                                }))
                              }
                              onChange={(selectedOption) =>
                                handleSelectChange(
                                  "group",
                                  selectedOption,
                                  "add"
                                )
                              }
                            />

                            {apiError?.group && (
                              <p className="text-danger">{apiError?.group}</p>
                            )}
                          </div>
                        </div>
                        <br />
                        <CustomInput
                          id="description"
                          type="text"
                          value={addLedgerData?.description}
                          onChange={(e) => handleChange(e, "add")}
                          placeholder="Description"
                          label="Description"
                          apiError={apiError?.description}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      type="reset"
                      className="btn btn-primary cancel me-2"
                      onClick={() => handleModalClose("#add_ledger")}
                    >
                      Cancel
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-primary"
                      // onClick={handleEditSubmit}
                    >
                      {postLedgerLoadig ? <Spin /> : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const editLedger = (
    <div
      id="edit_ledger"
      className="modal fade"
      // tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Edit Ledger</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={handleSubmit(handleUpdateSubmit)}>
                <div className="col-md-12">
                  <div className="form-group-item border-0 mb-0">
                    <div className="row align-item-center">
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <Controller
                          name="title"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="title"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e, "edit");
                              }}
                              placeholder="Title"
                              label="Title"
                              errorMsg={errors?.title?.message}
                              error={errors?.title}
                              apiError={editApiError?.title}
                              autoComplete="false"
                              required={true}
                            />
                          )}
                        />
                        <br />

                        <div className="row align-item-center">
                          <div className="col-lg-12 col-md-6 col-sm-12">
                            <label>Group</label>
                            <Select
                              id="group"
                              ref={(el) => (selectRefs.current["group"] = el)}
                              value={editLedgerData?.group}
                              options={
                                groupData?.length &&
                                groupData?.map((option) => ({
                                  value: option.id,
                                  label: option.title,
                                }))
                              }
                              onChange={(selectedOption) =>
                                handleSelectChange(
                                  "group",
                                  selectedOption,
                                  "edit"
                                )
                              }
                            />

                            {editApiError?.group && (
                              <p className="text-danger">
                                {editApiError?.group}
                              </p>
                            )}
                          </div>
                        </div>
                        <br />
                        <CustomInput
                          id="description"
                          type="text"
                          value={editLedgerData?.description}
                          onChange={(e) => handleChange(e, "edit")}
                          placeholder="Description"
                          label="Description"
                          apiError={editApiError?.description}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-end">
                    {" "}
                    {/* Use d-flex to align items in a row */}
                    <button
                      type="reset"
                      className="btn btn-primary cancel me-2"
                      onClick={() => handleModalClose("#edit_ledger")}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleEditSubmit}
                    >
                      {updateLedgerLoading ? <Spin /> : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <div className="list-btn">
        <ul className="filter-list">
          <li>
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Journal
          </li>
        </ul>
      </div> */}
      {/* Table */}
      {contextHolder}
      <div className="row">
        <div className="col-sm-12">
          {/* <Link
            className="btn btn-white addGrpBtn"
            data-bs-toggle="modal"
            // to="#add_ledger"
            role="button"
            onClick={() => setModalVisible(true)}
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Group
          </Link> */}
          <Link
            className="btn btn-primary addBtn"
            data-bs-toggle="modal"
            to="#add_ledger"
            role="button"
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            <span>Create</span> Ledger
          </Link>

          <div className=" card">
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  loading={ledgerListLoading}
                  pagination={{
                    total: ledgerListData?.count,
                    current: page?.page,
                    pageSize: page?.perPage,
                    onChange: handleTableChange,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={columns}
                  dataSource={ledgerListData?.results}
                  rowKey={(record) => record.Id}
                  className="chartofAccTables"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {createLedger}
      {editLedger}
    </>
  );
};

LedgerList.propTypes = {
  activeTab: PropTypes.string,
};

export default LedgerList;
