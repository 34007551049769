/* eslint-disable */
import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";

import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import { Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { setPage } from "../redux/actions/BankModuleAction";
import { useSelector } from "react-redux";

import { transactionViewApi } from "../redux/actions/BankModuleAction";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const TransactionView = () => {
  const [menu, setMenu] = useState(false);
  const history= useHistory()

  const transactionDetail = useSelector((state) => state?.transactionDetail);
  const {
    transactionViewDetail,
    loading: transactionViewLoading,
    page,
    perPage,
  } = transactionDetail;

  const { id } = useParams();

  const dispatch = useDispatch();

  const transactionColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Statement Details",
      align:"center",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Deposits",
      dataIndex: "deposit",
      sorter: (a, b) => a.deposit.length - b.deposit.length,
    },
    {
      title: "WithDrawals",
      dataIndex: "withdraw",
      sorter: (a, b) => a.withdraw.length - b.withdraw.length,
    },
  ];

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  useEffect(() => {
    if (id) {
      dispatch(transactionViewApi(page, perPage, id));
    }
  }, [id, page, perPage]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li onClick={()=>history.push('/banking')} className="breadcrumb-item">
                      <a>Banking</a>
                    </li>
                    <li className="breadcrumb-item active">Transaction Details</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body payments">
                    <div className="table-responsive table-hover">
                      <Table
                        loading={transactionViewLoading}
                        pagination={{
                          total: transactionViewDetail?.count,
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={transactionColumns}
                        dataSource={transactionViewDetail?.results}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TransactionView;
