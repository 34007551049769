import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";

import { Link } from "react-router-dom";

import { Plan2, Plan3 } from "../../src/_components/Imagepath";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";

const Subscription = () => {
  const [menu, setMenu] = useState(false);
  const datasource = [
    {
      id: 1,
      plan: "Free",
      subscribe_date: "15 Mar 2024",
      validity: "24 Mar 2024",
      amount: "0.00 $",
    },
    {
      id: 2,
      plan: "Smart",
      subscribe_date: "15 Mar 2024",
      validity: "15 Aug 2024",
      amount: "10.00 $",
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Plan",
      dataIndex: "plan",
      sorter: (a, b) => a.plan.length - b.plan.length,
    },
    {
      title: "Subscribe Date",
      dataIndex: "subscribe_date",
      sorter: (a, b) => a.subscribe_date.length - b.subscribe_date.length,
    },

    {
      title: "Validity",
      dataIndex: "validity",
      sorter: (a, b) => a.validity.length - b.validity.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
  ];

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  return (
    <div>
      <Header onMenuClick={() => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper customers">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Settings - Subscription</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrapper  login-body">
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <div className="carousel-container">
                  <Carousel
                    showArrows={true}
                    showIndicators={false}
                    showStatus={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          title={label}
                          style={{
                            position: "absolute",
                            zIndex: 2,
                            left: 15,
                            top: "50%",
                            transform: "translateY(-50%)",
                            backgroundColor: "transparent", // Transparent background
                            border: "none", // No border
                            cursor: "pointer", // Show pointer cursor
                          }}
                        >
                          {/* Empty span for the left arrow */}
                          <span
                            style={{
                              border: "solid red",
                              borderWidth: "0 2px 2px 0",
                              display: "inline-block",
                              padding: "5px",
                              transform: "rotate(135deg)",
                            }}
                          />
                        </button>
                      )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          title={label}
                          style={{
                            position: "absolute",
                            zIndex: 2,
                            right: 15,
                            top: "50%",
                            transform: "translateY(-50%)",
                            backgroundColor: "transparent", // Transparent background
                            border: "none", // No border
                            cursor: "pointer", // Show pointer cursor
                          }}
                        >
                          {/* Empty span for the right arrow */}
                          <span
                            style={{
                              border: "solid red",
                              borderWidth: "0 2px 2px 0",
                              display: "inline-block",
                              padding: "5px",
                              transform: "rotate(-45deg)",
                            }}
                          />
                        </button>
                      )
                    }
                  >
                    {/* Your carousel items here */}
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <div className="plan-header">
                            <span className="plan-widget-icon">
                              <img src="" alt="" />
                            </span>
                            <div className="plan-title">
                              <h6>For individuals</h6>
                              <h4 className="plan-name">Basic</h4>
                            </div>
                          </div>
                          <div className="description-content">
                            <p>
                              Lorem ipsum dolor sit amet doloroli sitiol conse
                              ctetur adipiscing elit.{" "}
                            </p>
                          </div>
                          <div className="price-dollar">
                            <h1 className="d-flex align-items-center">
                              $99<span className="ms-1">/monthly</span>
                            </h1>
                          </div>
                          <div className="plan-description">
                            <h6>What’s included</h6>
                            <ul>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                All analytics features
                              </li>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                Up to 250,000 tracked visits
                              </li>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                Normal support
                              </li>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                Up to 3 team members
                              </li>
                            </ul>
                          </div>
                          <div className="plan-button">
                            <Link
                              className="btn btn-primary d-flex align-items-center justify-content-center"
                              to="#"
                            >
                              Get Started
                              <span className="ms-2">
                                <i className="fe fe-arrow-right" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card price-selected">
                        <div className="card-body plan-header-selected">
                          <div className="d-flex">
                            <div className="plan-header">
                              <span className="plan-widget-icon">
                                <img src={Plan2} alt="" />
                              </span>
                              <div className="plan-title">
                                <h6 className="text-white">For Startups</h6>
                                <h4 className="plan-name text-white">Pro</h4>
                              </div>
                            </div>
                            <span className="price-popular">Popular</span>
                          </div>
                          <div className="description-content">
                            <p>
                              Lorem ipsum dolor sit amet doloroli sitiol conse
                              ctetur adipiscing elit.{" "}
                            </p>
                          </div>
                          <div className="price-dollar">
                            <h1 className="d-flex align-items-center text-white">
                              $199
                              <span className="text-white ms-1">/monthly</span>
                            </h1>
                          </div>
                          <div className="plan-description">
                            <h6 className="text-white">What’s included</h6>
                            <ul>
                              <li>
                                <span className="rounded-circle bg-white me-2">
                                  <i className="text-primary fe fe-check" />
                                </span>
                                All analytics features
                              </li>
                              <li>
                                <span className="rounded-circle bg-white me-2">
                                  <i className="text-primary fe fe-check" />
                                </span>
                                Up to 1,000,000 tracked visits
                              </li>
                              <li>
                                <span className="rounded-circle bg-white me-2">
                                  <i className="text-primary fe fe-check" />
                                </span>
                                Premium support
                              </li>
                              <li>
                                <span className="rounded-circle bg-white me-2">
                                  <i className="text-primary fe fe-check" />
                                </span>
                                Up to 10 team members
                              </li>
                            </ul>
                          </div>
                          <div className="plan-button">
                            <Link
                              className="btn btn-white d-flex align-items-center justify-content-center"
                              to="#"
                            >
                              Get Started
                              <span className="ms-2">
                                <i className="fe fe-arrow-right" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <div className="plan-header">
                            <span className="plan-widget-icon">
                              <img src={Plan3} alt="" />
                            </span>
                            <div className="plan-title">
                              <h6>For individuals</h6>
                              <h4 className="plan-name">Basic</h4>
                            </div>
                          </div>
                          <div className="description-content">
                            <p>
                              Lorem ipsum dolor sit amet doloroli sitiol conse
                              ctetur adipiscing elit.{" "}
                            </p>
                          </div>
                          <div className="price-dollar">
                            <h1 className="d-flex align-items-center">
                              $399<span className="ms-1">/monthly</span>
                            </h1>
                          </div>
                          <div className="plan-description">
                            <h6>What’s included</h6>
                            <ul>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                All analytics features
                              </li>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                Up to 5,000,000 tracked visits
                              </li>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                Dedicated support
                              </li>
                              <li>
                                <span className="rounded-circle me-2">
                                  <i className="fe fe-check" />
                                </span>
                                Up to 50 team members
                              </li>
                            </ul>
                          </div>
                          <div className="plan-button">
                            <Link
                              className="btn btn-primary d-flex align-items-center justify-content-center"
                              to="#"
                            >
                              Get Started
                              <span className="ms-2">
                                <i className="fe fe-arrow-right" />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="row">
          <h5>Subscription Invoice History</h5>
          <div className="col-sm-12">
            <div className="card customers">
              <div className="card-body">
                <div className="table-container">
                  <Table
                    className="table"
                    pagination={{
                      total: datasource.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange, // If needed
                      itemRender: itemRender, // If needed
                    }}
                    columns={columns}
                    dataSource={datasource}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
