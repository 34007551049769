export const GET_CUSTOMERLIST_REQUSET = 'GET_CUSTOMERLIST_REQUSET'
export const GET_CUSTOMERLIST_SUCCESS = 'GET_CUSTOMERLIST_SUCCESS'
export const GET_CUSTOMERLIST_FAIL = 'GET_CUSTOMERLIST_FAIL'
export const CUSTOMERLISTSET_PAGE = 'SET_PAGE';
export const CUSTOMER_EDIT_DETAIL_REQUEST =
  "CUSTOMER_EDIT_DETAIL_REQUEST";
export const CUSTOMER_EDIT_DETAIL_SUCCESS =
  "CUSTOMER_EDIT_DETAIL_SUCCESS";
export const CUSTOMER_EDIT_DETAIL_FAIL =
  "CUSTOMER_EDIT_DETAIL_FAIL";
export const CUSTOMER_EDIT_REQUEST = "CUSTOMER_EDIT_REQUEST";
export const CUSTOMER_EDIT_SUCCESS = "CUSTOMER_EDIT_SUCCESS";
export const CUSTOMER_EDIT_FAIL = "CUSTOMER_EDIT_FAIL";
export const CUSTOMER_EDIT_RESET = "CUSTOMER_EDIT_RESET";
export const CUSTOMER_ADD_REQUEST = 'CUSTOMER_ADD_REQUEST';
export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS';
export const CUSTOMER_ADD_FAIL = 'CUSTOMER_ADD_FAIL';
export const CUSTOMER_ADD_RESET = 'CUSTOMER_ADD_RESET';
