import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useSelector } from "react-redux";
import { groupUpdationApi } from "../redux/actions/LedgerModuleAction";
import { useDispatch } from "react-redux";
import { message, Spin } from "antd";
import { GROUP_UPDATE_RESET } from "../redux/constants/ledgerListModuleConstant";

const GroupUpdationModal = ({
  visible,
  onCancel,
  onOk,
  handleModalClose,
  formData,
  setFormData,
  groupData,
  getLedgerGroupList,
  page,
  perPage,
  modalType,
  parentGroups,
}) => {

  const [editError, setEditError] = useState({});
  

  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const selectRefs = useRef({});

  const groupUpdation = useSelector((state) => state.groupUpdation);
  const { loading, success, data, error } = groupUpdation;

  const natureOfGroupOptions = [
    { value: "assets", label: "Assets" },
    { value: "liability", label: "Liability" },
    { value: "income", label: "Income" },
    { value: "expense", label: "Expense" },
    { value: "equity", label: "Equity" },
  ];

  useEffect(() => {
    if (success) {
      onOk(data, "editModal");
      setFormData({
        nameOfGroup: "",
        parentGroup: null,
        isPrimaryGroup: false,
      });
      messageApi.open({
        type: "success",
        content: "Group Updated Successfully",
        duration: 5,
      });
      dispatch(getLedgerGroupList(page, perPage));
      handleModalClose("#edit_group");
      dispatch({ type: GROUP_UPDATE_RESET });
    }
  }, [success, data]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setEditError((prevState) => ({ ...prevState, title: "" }));
    setFormData((prevData) => {
      const newData = { ...prevData, [name]: newValue };

      // Clear parentGroup if isPrimaryGroup changes
      if (name === "isPrimaryGroup") {
        newData.parentGroup = null;
      }

      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      title: formData.nameOfGroup,
      // ...(modalType === "tally" && {
        is_primary: formData.isPrimaryGroup,
      // }),
      ...(formData.isPrimaryGroup
        ? { nature_of_group: formData.parentGroup?.value }
        : { parent_group: formData.parentGroup?.value }),
    };
    dispatch(groupUpdationApi(data, formData?.id));
    
  };
  const getParentOptions = () => {
    if (formData.isPrimaryGroup) {
      return natureOfGroupOptions;
    } else {
      return parentGroups.map((group) => ({
        label: group.label,
        options: group.options.map((option) => ({
          value: option.value,
          label: option.label,
        })),
      }));
    }
  };

  // Define options based on isPrimaryGroup
  // const getParentOptions = () => {
  //   if (formData.isPrimaryGroup) {
  //     // Simple list of heads when isPrimaryGroup is true
  //     return [
  //       { value: "equity", label: "Equity" },
  //       { value: "income", label: "Income" },
  //       { value: "expense", label: "Expense" },
  //       { value: "investment", label: "Investment" },
  //     ];
  //   } else {
  //     // Grouped options when isPrimaryGroup is false
  //     return [
  //       {
  //         label: "Equity",
  //         options: [
  //           { value: "equity_1", label: "Equit" },
  //           // { value: "equity_2", label: "Equity Subheading 2" },
  //         ]
  //       },
  //       {
  //         label: "Income",
  //         options: [
  //           { value: "income_1", label: "Income" },
  //           { value: "income_2", label: "Other Income" },
  //         ]
  //       },
  //       {
  //         label: "Expense",
  //         options: [
  //           { value: "expense_1", label: "Expense" },
  //           // { value: "expense_2", label: "Expense Subheading 2" },
  //         ]
  //       },
  //       {
  //         label: "Investment",
  //         options: [
  //           { value: "investment_1", label: "Investment " },
  //           { value: "investment_2", label: "Other Investment" },
  //         ]
  //       }
  //     ];
  //   }
  // };

  useEffect(() => {
    if (groupData && parentGroups?.length) {
      const parentGroupList = parentGroups?.map((el) => el?.options)?.flat();
      const parentGroupDetail = parentGroupList?.find(
        (el) => el?.value === groupData?.parent_group
      );
      const natureOfGroupDetail = natureOfGroupOptions?.find(
        (el) => el?.value === groupData?.nature_of_group
      );
      setFormData((prevState) => ({
        ...prevState,
        nameOfGroup: groupData?.title,
        parentGroup: parentGroupDetail
          ? parentGroupDetail
          : natureOfGroupDetail
          ? natureOfGroupDetail
          : null,
        isPrimaryGroup: groupData?.is_primary,
      }));
    }
  }, [groupData]);

  useEffect(() => {
    if (error) {
      setEditError(error);
    }
  }, [error]);

  useEffect(() => {
    const groupFields = ["title", "parent_group"];
    const firstErrorField = groupFields.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "parent_group") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  const customStyles = {
    group: (provided) => ({
      ...provided,
      padding: "0",
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      marginBottom: "4px",
      marginTop: "4px",
      paddingLeft: "8px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#6366F1" : "white",
      color: state.isSelected ? "white" : "#000",
      "&:hover": {
        backgroundColor: "#EAECEF",
        color: "#000",
      },
      paddingLeft: "16px",
    }),
    control: (provided) => ({
      ...provided,
      borderColor: "#D1D5DB",
      "&:hover": {
        borderColor: "#6366F1",
      },
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow:
        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    }),
  };

  return (
    <>
      {contextHolder}
      <div
        id="edit_group"
        className={`modal fade ${visible ? "show d-block" : ""}`}
        role="dialog"
        aria-hidden={!visible}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Update Groups</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              />
            </div>
            <div className="modal-body p-4">
              <form onSubmit={handleSubmit}>
                {/* Common UI elements for Tally and Zoho groups */}
                {(modalType === "tally" || modalType === "zoho") && (
                  <>
                    <div className="form-group mb-3">
                      <label htmlFor="nameOfGroup">Name of Group</label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="nameOfGroup"
                        placeholder="Enter group name"
                        value={formData.nameOfGroup}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    {editError?.title && (
                      <p className="text-danger">{editError?.title}</p>
                    )}

                    {modalType === "tally" && (
                      <div className="form-group mb-3">
                        <label htmlFor="isPrimaryGroup">
                          Is it Primary Group
                        </label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="isPrimaryGroup"
                            name="isPrimaryGroup"
                            checked={formData.isPrimaryGroup}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <label htmlFor="parentGroup">
                        {formData.isPrimaryGroup ? "Head" : "Parent Group"}
                      </label>
                      {parentGroups && (
                        <Select
                          id="parent_group"
                          value={formData.parentGroup}
                          styles={customStyles}
                          ref={(el) =>
                            (selectRefs.current["parent_group"] = el)
                          }
                          options={getParentOptions()}
                          placeholder={
                            formData.isPrimaryGroup
                              ? "Select a head"
                              : "Select a primary group"
                          }
                          onChange={(selectedOption) => {
                            setFormData((prev) => ({
                              ...prev,
                              parentGroup: selectedOption,
                            }));
                            setEditError((prevState) => ({
                              ...prevState,
                              parent_group: "",
                            }));
                          }}
                        />
                      )}
                    </div>
                    {editError?.parent_group && (
                      <p className="text-danger">{editError?.parent_group}</p>
                    )}
                  </>
                )}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary">
                    {loading ? <Spin /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GroupUpdationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  groupData: PropTypes.object,
  getLedgerGroupList: PropTypes.func,
  page: PropTypes.number,
  perPage: PropTypes.number,
  modalType: PropTypes.string,
  parentGroups: PropTypes.array,
};

export default GroupUpdationModal;
