export default {
  Data: [
    {
      id: "1",
      Name: "Glancewise",
      BankName: "Axis Bank",
      Branch: "Adambakkam",
      AccountNumber: "123 456 78920",
      IFSCCode: "UTIB0003259",
    },
    {
      id: "3",
      Name: "Glancewise",
      BankName: "Canara Bank",
      Branch: "Karavalur",
      AccountNumber: "756 890 75689",
      IFSCCode: "CNRB0001483",
      Action: "Edit",
    },
    {
      id: "4",
      Name: "Glancewise",
      BankName: "ICICI Bank",
      Branch: "Vadavalli",
      AccountNumber: "756 890 36586",
      IFSCCode: "ICIC0002142",
      Action: "Edit",
    },
    {
      id: "5",
      Name: "Glancewise",
      BankName: "Karur Vysya Bank",
      Branch: "Chennai",
      AccountNumber: "756 890 88669",
      IFSCCode: "KVBL0001153",
      Action: "Edit",
    },
    {
      id: "6",
      Name: "Glancewise",
      BankName: "State Bank of India",
      Branch: "Chennai",
      AccountNumber: "756 890 36589",
      IFSCCode: "SBIN0000800",
      Action: "Edit\n",
    },
  ],
};
