import {
  ZOHOAUTH_LIST_FAIL,
  ZOHOAUTH_LIST_REQUEST,
  ZOHOAUTH_LIST_RESET,
  ZOHOAUTH_LIST_SUCCESS,
  ZOHOCRM_CREATE_FAIL,
  ZOHOCRM_CREATE_REQUEST,
  ZOHOCRM_CREATE_RESET,
  ZOHOCRM_CREATE_SUCCESS,
  ZOHOCRM_LIST_FAIL,
  ZOHOCRM_LIST_REQUEST,
  ZOHOCRM_LIST_SUCCESS,
} from "./CrmandErpConstant";

const initialState = {
  checkOrgId: {},
  loading: false,
  error: null,
  redirection: {},
};

export const zohoCrmCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ZOHOCRM_CREATE_REQUEST:
      return { loading: true };

    case ZOHOCRM_CREATE_SUCCESS:
      return { loading: false, success: true };

    case ZOHOCRM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ZOHOCRM_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const checkZohoOrgIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case ZOHOCRM_LIST_REQUEST:
      return { ...state, loading: true, error: null };

    case ZOHOCRM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        checkOrgId: action.payload.data,
      };
    case ZOHOCRM_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const zohoAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ZOHOAUTH_LIST_REQUEST:
      return { ...state, loading: true, error: null };

    case ZOHOAUTH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        redirection: action.payload.data,
      };
    case ZOHOAUTH_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ZOHOAUTH_LIST_RESET:
      return {};
    default:
      return state;
  }
};