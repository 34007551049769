import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useSelector } from "react-redux";
import { groupCreationApi } from "../redux/actions/LedgerModuleAction";
import { useDispatch } from "react-redux";
import { message, Spin } from "antd";
import { GROUP_CREATE_RESET } from "../redux/constants/ledgerListModuleConstant";

const GroupCreationModal = ({ visible, onCancel, onOk, handleModalClose,modalType,parentGroups }) => {

  const [formData, setFormData] = useState({
    nameOfGroup: "",
    parentGroup: null,
    isPrimaryGroup: false,
    // cashAssets: "",
    // fixedAssets: ""
  });

  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const groupCreation = useSelector((state) => state.groupCreation);
  const { loading, success, data } = groupCreation;

  const natureOfGroupOptions = [
    { value: "assets", label: "Assets" },
    { value: "liability", label: "Liability" },
    { value: "income", label: "Income" },
    { value: "expense", label: "Expense" },
    { value: "equity", label: "Equity" },
  ];


  useEffect(() => {
    if (success) {
      onOk(data, "addModal");
      setFormData({
        nameOfGroup: "",
        parentGroup: null,
        isPrimaryGroup: false,
      });
      messageApi.open({
        type: "success",
        content: "Group Created Successfully",
        duration: 5,
      });
      handleModalClose("#add_group");
      dispatch({ type: GROUP_CREATE_RESET });
    }
  }, [success, data]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => {
      const newData = { ...prevData, [name]: newValue };

      // Clear parentGroup if isPrimaryGroup changes
      if (name === "isPrimaryGroup") {
        newData.parentGroup = null;
      }

      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      title: formData.nameOfGroup,
      ...(modalType === "tally" && { is_primary: formData.isPrimaryGroup }),
      ...(formData.isPrimaryGroup
        ? { nature_of_group: formData.parentGroup?.value }
        : { parent_group: formData.parentGroup?.value }),
    };
    dispatch(groupCreationApi(data));
  };
  const getParentOptions = () => {
    if (formData.isPrimaryGroup) {
      return natureOfGroupOptions;
    } else {
      // parentGroups is already in the correct structure, we just need to map it correctly
      return parentGroups.map(group => ({
        label: group.label,
        options: group.options.map(option => ({
          value: option.value,
          label: option.label
        }))
      }));
    }
  };

  const customStyles = {
    group: (provided) => ({
      ...provided,
      padding: '0',
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#000',
      marginBottom: '4px',
      marginTop:'4px',
      paddingLeft: '8px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6366F1' : 'white',
      color: state.isSelected ? 'white' : '#000',
      '&:hover': {
        backgroundColor: '#EAECEF',
        color: '#000',
      },
      paddingLeft: '16px',
    }),
    control: (provided) => ({
      ...provided,
      borderColor: '#D1D5DB',
      '&:hover': {
        borderColor: '#6366F1',
      },
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    }),
  };

  return (
    <>
      {contextHolder}
      <div
        id="add_group"
        className={`modal fade ${visible ? "show d-block" : ""}`}
        role="dialog"
        aria-hidden={!visible}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create Ledger Group</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              />
            </div>
            <div className="modal-body p-4">
              <form onSubmit={handleSubmit}>
                {/* Common UI elements for Tally and Zoho groups */}
                {(modalType === "tally" || modalType === "zoho") && (
                  <>
                    <div className="form-group mb-3">
                      <label htmlFor="nameOfGroup">Name of Group</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nameOfGroup"
                        name="nameOfGroup"
                        placeholder="Enter group name"
                        value={formData.nameOfGroup}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    {modalType === "tally" && (
                      <div className="form-group mb-3">
                        <label htmlFor="isPrimaryGroup">
                          Is it Primary Group
                        </label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="isPrimaryGroup"
                            name="isPrimaryGroup"
                            checked={formData.isPrimaryGroup}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <label htmlFor="parentGroup">
                        {formData.isPrimaryGroup ? "Head" : "Parent Group"}
                      </label>
                      {parentGroups && (
                    <Select
                      value={formData.parentGroup}
                      options={getParentOptions()}
                      styles={customStyles}
                      placeholder={
                        formData.isPrimaryGroup
                          ? "Select a head"
                          : "Select a primary group"
                      }
                      onChange={(selectedOption) =>
                        setFormData((prev) => ({
                          ...prev,
                          parentGroup: selectedOption,
                        }))
                      }
                    />
                  )}
                    </div>
                  </>
                )}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? <Spin /> : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GroupCreationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func,
  modalType:PropTypes.string,
  parentGroups:PropTypes.array
};

export default GroupCreationModal;
