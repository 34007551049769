import React from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";

const SchedulerModal = ({ show, schedulerError, handleSubmit, loading }) => {
  const schedulerModal = (
    <div
      className="modal custom-modal fade submit-erp"
      id={"invoice_scheduler"}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form-header">
              <p>Are you sure want to run Scheduler?</p>
            </div>
            {schedulerError?.detail && (
              <>
                <p className="text-danger" style={{ textAlign: "center" }}>
                  {schedulerError?.detail}
                </p>
                <br />
              </>
            )}
            {schedulerError?.message && (
              <>
                <p className="text-danger" style={{ textAlign: "center" }}>
                  {schedulerError?.message}
                </p>
                <br />
              </>
            )}
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <button
                    className="w-100 btn btn-primary paid-continue-btn"
                    onClick={handleSubmit}
                  >
                    {loading ? <Spin /> : "Yes"}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="reset"
                    data-bs-dismiss="modal"
                    className="w-100 btn btn-primary paid-cancel-btn"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{schedulerModal}</>;
};

export default SchedulerModal;

SchedulerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  schedulerError: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
