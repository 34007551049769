import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePickerComponent = ({ startDate, endDate, handleDateChange }) => {
  const [range, setRange] = useState([startDate, endDate]);

  useEffect(() => {
    setRange([startDate, endDate]);  // Update state if props change
  }, [startDate, endDate]);

  const handleRangeChange = (dates) => {
    const [start, end] = dates;
    setRange([start, end]);
    if (start && end) {
      handleDateChange(start, end);
    }
  };

  return (
    <div>
      <DatePicker
        selected={range[0]}          // Start date selected
        onChange={handleRangeChange}  // Date range selection handler
        startDate={range[0]}          // The start of the range
        endDate={range[1]}            // The end of the range
        selectsRange                  // Enable range selection
        placeholderText="Select Date Range"
        className="form-control datetimepicker"
      />
    </div>
  );
};

// Prop types validation
DateRangePickerComponent.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
};

export default DateRangePickerComponent;
