
export const VENDOR_ADD_REQUEST = 'VENDOR_ADD_REQUEST';
export const VENDOR_ADD_SUCCESS = 'VENDOR_ADD_SUCCESS';
export const VENDOR_ADD_FAIL = 'VENDOR_ADD_FAIL';
export const VENDOR_ADD_RESET = 'VENDOR_ADD_RESET';
export const GET_VENDORLIST_REQUSET = 'GET_VENDORLIST_REQUSET'
export const GET_VENDORLIST_SUCCESS = 'GET_VENDORLIST_SUCCESS'
export const GET_VENDORLIST_FAIL = 'GETVENDORLIST_FAIL'
export const VENDORLISTSET_PAGE = 'VENDORLISTSET_PAGE' 

export const VENDOR_EDIT_DETAIL_REQUEST =
  "VENDOR_EDIT_DETAIL_REQUEST";
export const VENDOR_EDIT_DETAIL_SUCCESS =
  "VENDOR_EDIT_DETAIL_SUCCESS";
export const VENDOR_EDIT_DETAIL_FAIL =
  "VENDOR_EDIT_DETAIL_FAIL";
export const VENDOR_EDIT_REQUEST = "VENDOR_EDIT_REQUEST";
export const VENDOR_EDIT_SUCCESS = "VENDOR_EDIT_SUCCESS";
export const VENDOR_EDIT_FAIL = "VENDOR_EDIT_FAIL";
export const VENDOR_EDIT_RESET = "VENDOR_EDIT_RESET";
export const VENDOR_SEARCH_REQUEST = 'VENDOR_SEARCH_REQUEST'
export const VENDOR_SEARCH_SUCCESS = 'VENDOR_SEARCH_SUCCESS'
export const VENDOR_SEARCH_FAIL = 'VENDOR_SEARCH_FAIL'
export const VENDOR_SEARCH_RESET = 'VENDOR_SEARCH_RESET'
export const VENDOR_EXISTING_ADD_REQUEST = 'VENDOR_EXISTING_ADD_REQUEST';
export const VENDOR_EXISTING_ADD_SUCCESS = 'VENDOR_EXISTING_ADD_SUCCESS';
export const VENDOR_EXISTING_ADD_FAIL = 'VENDOR_EXISTING_ADD_FAIL';
export const VENDOR_EXISTING_ADD_RESET = 'VENDOR_EXISTING_ADD_RESET';
