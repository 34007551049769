/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../_components/antd.css";
import { Table } from "antd";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import {
  getLedgerGroupList,
  groupCreationApi,
  groupDetailApi,
} from "../../redux/actions/LedgerModuleAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import GroupCreationModal from "../../components/GroupCreationModal";
import GroupUpdationModal from "../../components/GroupUpdationModal";
import axios from "axios";
import { HTTP } from "../../redux/api";

const LedgerGroup = ({ activeTab }) => {
  const [modalVisible, setModalVisible] = useState({
    addModal: false,
    editModal: false,
  });
  const [editData, setEditData] = useState({
    nameOfGroup: "",
    parentGroup: null,
    isPrimaryGroup: false,
    // cashAssets: "",
    // fixedAssets: ""
  });
  const [parentGroups, setParentGroups] = useState([]);
  const [modalType, setModalType] = useState("");

  const handleOk = (values, modalId) => {
    console.log("Form values:", values);
    setModalVisible((prevState) => ({ ...prevState, [modalId]: false }));
  };
  const [page, setPage] = useState({ page: 1, perPage: 10 });

  const ledgerGroupList = useSelector((state) => state?.ledgerGroupList);
  const { loading, data } = ledgerGroupList;

  const groupDetail = useSelector((state) => state?.groupDetail);
  const { loading: groupDetailLoading, data: groupData } = groupDetail;

  const userData = useSelector((state) => state.userSignin);
  const { userInfo } = userData;

  const dispatch = useDispatch();

  const handleEditClick = (data) => {
    dispatch(groupDetailApi(data?.id));
    setEditData((prevState) => ({ ...prevState, id: data?.id }));
    setModalVisible((prevState) => ({ ...prevState, editModal: true }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      render: (text, record, index) =>
        page?.perPage * (page?.page - 1) + index + 1,
    },
    {
      title: "Name",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          <Link
            className="btn btn-primary me-2"
            data-bs-toggle="modal"
            to="#edit_group"
            role="button"
            onClick={() => handleEditClick(record)}
          >
            <i className="far fa-edit" />
          </Link>
          {/* <a className="btn btn-greys">
            <i className="far fa-send" />
          </a> */}
        </>
      ),
    },
  ];

  const handleTableChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {});
  };

  const onModalClose = () => {
    $("#add_group").on("hide.bs.modal", () => {
      setModalVisible((prevState) => ({ ...prevState, addModal: false }));
    });
    $("#edit_group").on("hide.bs.modal", () => {
      setModalVisible((prevState) => ({ ...prevState, editModal: false }));
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  useEffect(() => {
    if (activeTab?.includes("#ledger_group")) {
      dispatch(getLedgerGroupList(page?.page, page?.perPage));
    }
  }, [dispatch, page?.page, page?.perPage, activeTab]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const fetchData = async () => {
    try {
      const response = await HTTP.get(`/erp/linked/`, {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      });

      setModalType(response.data.erp || "tally");
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchParent = async () => {
    try {
      const response = await HTTP.get(
        `/ledger/ledger-group/heads/`,
        {
          headers: {
            Authorization: `Bearer ${userInfo}`,
          },
        }
      );

      setParentGroups(response.data);
      // setModalType(response.data.erp || 'tally')
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (activeTab?.includes("#ledger_group")) {
      fetchData();
      fetchParent();
    }
  }, [activeTab]);

  return (
    <>
      {/* <div className="list-btn">
        <ul className="filter-list">
          <li>
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Journal
          </li>
        </ul>
      </div> */}
      {/* Table */}
      <div className="row">
        <div className="col-sm-12">
          {/* <Link
            className="btn btn-white addGrpBtn"
            data-bs-toggle="modal"
            // to="#add_ledger"
            role="button"
            onClick={() => setModalVisible(true)}
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Group
          </Link> */}
          {/* <Link
            className="btn btn-white addGrpBtn"
            data-bs-toggle="modal"
            to="#add_group"
            role="button"
            onClick={() => setModalVisible(true)}
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Group
          </Link> */}
          <Link
            className="btn btn-primary addBtn"
            data-bs-toggle="modal"
            to="#add_group"
            role="button"
            onClick={() =>
              setModalVisible((prevState) => ({
                ...prevState,
                addModal: false,
              }))
            }
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Group
          </Link>
          <div className=" card">
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  loading={loading}
                  pagination={{
                    total: data?.length,
                    current: page?.page,
                    pageSize: page?.perPage,
                    onChange: handleTableChange,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.Id}
                  className="chartofAccTables"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GroupCreationModal
        visible={modalVisible?.addModal}
        onCancel={() =>
          setModalVisible((prevState) => ({ ...prevState, addModal: false }))
        }
        onOk={handleOk}
        handleModalClose={handleModalClose}
        modalType={modalType}
        parentGroups={parentGroups}
      />
      <GroupUpdationModal
        visible={modalVisible?.editModal}
        onCancel={() =>
          setModalVisible((prevState) => ({ ...prevState, editModal: false }))
        }
        onOk={handleOk}
        handleModalClose={handleModalClose}
        formData={editData}
        setFormData={setEditData}
        groupData={groupData}
        getLedgerGroupList={getLedgerGroupList}
        page={page?.page}
        perPage={page?.perPage}
        modalType={modalType}
        parentGroups={parentGroups}
      />
    </>
  );
};

LedgerGroup.propTypes = {
  activeTab: PropTypes.string,
};

export default LedgerGroup;
