import { HTTP } from "../api";
import {
  GET_REPORTLIST_FAIL,
  GET_REPORTLIST_PAGE,
  GET_REPORTLIST_REQUSET,
  GET_REPORTLIST_SUCCESS,
} from "../constants/ReportConstant";

export const setPage = (page, perPage) => ({
  type: GET_REPORTLIST_PAGE,
  payload: page,
  perPage,
});

export const getReportList = (page = 1, perPage = 10) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_REPORTLIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();

  try {
    const { data } = await HTTP.get(
      `/users/data-processing/?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_REPORTLIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: GET_REPORTLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};