// import axios from "axios";
import { HTTP } from "../api";
import {
  RECURRINGINVOICE_CREATE_REQUEST,
  RECURRINGINVOICE_CREATE_SUCCESS,
  RECURRINGINVOICE_CREATE_FAIL,
  RECURRINGINVOICE_LIST_REQUEST,
  RECURRINGINVOICE_LIST_SUCCESS,
  RECURRINGINVOICE_LIST_FAIL,
  RECURRINGINVOICE_EDIT_DETAIL_REQUEST,
  RECURRINGINVOICE_EDIT_DETAIL_SUCCESS,
  RECURRINGINVOICE_EDIT_DETAIL_FAIL,
  RECURRINGINVOICE_EDIT_REQUEST,
  RECURRINGINVOICE_EDIT_SUCCESS,
  RECURRINGINVOICE_EDIT_FAIL,
  RECURRINGINVOICE_GET_INVOICELIST_PAGE,
  AP_RECURRINGINVOICE_LIST_REQUEST,
  AP_RECURRINGINVOICE_LIST_SUCCESS,
  AP_RECURRINGINVOICE_LIST_FAIL,
  AP_RECURRINGINVOICE_CREATE_REQUEST,
  AP_RECURRINGINVOICE_CREATE_SUCCESS,
  AP_RECURRINGINVOICE_CREATE_FAIL,
  AP_RECURRINGINVOICE_EDIT_DETAIL_REQUEST,
  AP_RECURRINGINVOICE_EDIT_DETAIL_SUCCESS,
  AP_RECURRINGINVOICE_EDIT_DETAIL_FAIL,
  AP_RECURRINGINVOICE_EDIT_REQUEST,
  AP_RECURRINGINVOICE_EDIT_SUCCESS,
  AP_RECURRINGINVOICE_EDIT_FAIL,
  AP_RECURRINGINVOICE_SCHEDULE_REQUEST,
  AP_RECURRINGINVOICE_SCHEDULE_SUCCESS,
  AP_RECURRINGINVOICE_SCHEDULE_FAIL,
  AR_RECURRINGINVOICE_SCHEDULE_REQUEST,
  AR_RECURRINGINVOICE_SCHEDULE_SUCCESS,
  AR_RECURRINGINVOICE_SCHEDULE_FAIL,
} from "../constants/RecurringInvoiceConstant";

export const recurringInvoiceCreate = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RECURRINGINVOICE_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      "/ar/recurring-invoices/create/",
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: RECURRINGINVOICE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const setPage = (page, perPage) => ({
  type: RECURRINGINVOICE_GET_INVOICELIST_PAGE,
  payload: page,
  perPage,
});

export const getRecurringInvoiceList = (
  page = 1,
  perPage = 10,
  customer__name,
  start_date,
  end_date,
  interval,
  day,
  nature_of_supply
) => async (dispatch, getState) => {
  dispatch({ type: RECURRINGINVOICE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/ar/recurring-invoices/?page=${page}&per_page=${perPage}&customer__name=${customer__name}&start_date=${start_date}&end_date=${end_date}&schedule_type=${interval}&day=${day}&description=${nature_of_supply}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: RECURRINGINVOICE_LIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const recurringEditInvoiceDetail = (id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RECURRINGINVOICE_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/ar/recurring-invoices/${id}/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: RECURRINGINVOICE_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_EDIT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateRecurringInvoice = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RECURRINGINVOICE_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(
      `/ar/recurring-invoices/${id}/update/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: RECURRINGINVOICE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_EDIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getAPRecurringInvoiceList = (
  page = 1,
  perPage = 10,
  vendor_name,
  start_date,
  end_date,
  interval,
  day,
  nature_of_supply
) => async (dispatch, getState) => {
  dispatch({ type: AP_RECURRINGINVOICE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/invoice/recurring-invoices/?page=${page}&per_page=${perPage}&vendor_name=${vendor_name}&start_date=${start_date}&end_date=${end_date}&schedule_type=${interval}&day=${day}&description=${nature_of_supply}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: AP_RECURRINGINVOICE_LIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: AP_RECURRINGINVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const apRecurringInvoiceCreate = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: AP_RECURRINGINVOICE_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      "/invoice/recurring-invoices/create/",
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: AP_RECURRINGINVOICE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_RECURRINGINVOICE_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const apRecurringEditInvoiceDetail = (id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: AP_RECURRINGINVOICE_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/invoice/recurring-invoices/${id}/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: AP_RECURRINGINVOICE_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_RECURRINGINVOICE_EDIT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateApRecurringInvoice = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: AP_RECURRINGINVOICE_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(
      `/invoice/recurring-invoices/${id}/update/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: AP_RECURRINGINVOICE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_RECURRINGINVOICE_EDIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const apRecurringSchedulerApi = () => async (dispatch, getState) => {
  dispatch({ type: AP_RECURRINGINVOICE_SCHEDULE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/invoice/mimic-scheduler/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: AP_RECURRINGINVOICE_SCHEDULE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AP_RECURRINGINVOICE_SCHEDULE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const arRecurringSchedulerApi = () => async (dispatch, getState) => {
  dispatch({ type: AR_RECURRINGINVOICE_SCHEDULE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/ar/mimic-scheduler/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: AR_RECURRINGINVOICE_SCHEDULE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AR_RECURRINGINVOICE_SCHEDULE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
