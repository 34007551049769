import PropTypes from 'prop-types'; // Import PropTypes
import React, { Component } from 'react';
import Sidebar from "../layouts/Sidebar";
import { Link, withRouter } from "react-router-dom";
import Header from "../layouts/Header";

class ErrorBoundary extends Component {
 constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, menu: false };
 }

 static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
 }

 componentDidCatch(error) {
    // You can also log the error to an error reporting service
    // Correctly update the state with the error
    this.setState({ error: error });
 }

 componentDidUpdate(prevProps) {
    // Check if the route has changed
    if (this.props.location !== prevProps.location) {
      // Reset the error state
      this.setState({ hasError: false, error: null });
    }
 }

 render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // Display the error message along with the fallback UI
      return (
        <>
        {/* <StickySidebar /> */}
        <div className={`main-wrapper ${this.state.menu ? "slide-nav" : ""}`}>
        <Header />
        <Sidebar />
        <div className="main-wrapper" style={{marginTop:'60px'}}>
          <div className="error-box">
            <h1>404</h1>
            <h3 className="h2 mb-3">
              <i className="fas fa-exclamation-circle"></i>  Something went wrong. Error: {this.state.error && this.state.error.message}
            </h3>
            <p className="h4 font-weight-normal">
            please go to any other pages or refresh
            </p>
            <Link to="/index" class="btn btn-primary">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
        </>
      )    
    }
    return this.props.children; 
 }
}

ErrorBoundary.propTypes = {
 children: PropTypes.node.isRequired, // Validate children prop
 location: PropTypes.object.isRequired,// Validate location prop
};

// Wrap the ErrorBoundary component with withRouter to inject history props
export default withRouter(ErrorBoundary);
