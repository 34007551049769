import React from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { uploadBankInfo } from "../redux/actions/BankModuleAction";
import CreateModal from "../_components/modalbox/createmodal";
import { Spin } from "antd";
import PropTypes from "prop-types";

const BankStatementUpload = ({
  modalRef,
  show,
  uploadData,
  bankDropdownList,
  setUploadData,
  uploadError,
  selectRefs,
  setUploadError,
  fileInputRef,
  setFile,
  file,
  handleModalClose,
  uploadLoading,
}) => {
  const dispatch = useDispatch();

  const uploadBankSubmit = (event) => {
    event.preventDefault();
    const form_data = new FormData();
    file.forEach((f) => form_data.append("file", f));
    form_data?.append("password", uploadData?.password);
    form_data?.append("bank", uploadData?.bank?.value);
    dispatch(uploadBankInfo(form_data));
  };

  const handleUploadChange = (e) => {
    const { id, value } = e.target;
    setUploadData((prevState) => ({ ...prevState, [id]: value }));
    setUploadError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleFileChange = (event) => {
    setFile(Array.from(event.target.files));
    setUploadError((prevState) => ({ ...prevState, file: "" }));
  };

  const uploadBankStatement = (
    <CreateModal
      id="upload_bank_info"
      modalRef={modalRef}
      show={show}
      title="Upload Bank Statement"
      modalbody={
        <>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={uploadBankSubmit}>
                <div className="col-md-12">
                  <div className="form-group-item border-0 mb-0">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                        <label>
                          Bank <span className="text-danger">*</span>
                        </label>
                        <Select
                          value={uploadData?.bank}
                          ref={(el) => (selectRefs.current["bank"] = el)}
                          options={bankDropdownList?.map((option) => ({
                            value: option.id,
                            label: option.bank_name && option.account_number 
                            ? `${option.bank_name} - ${option.account_number}` 
                            : `${option.bank_name} - ${option.account_name}`,
                          }))}
                          required
                          onChange={(selectedOption) => {
                            setUploadData((prevState) => ({
                              ...prevState,
                              bank: selectedOption,
                            }));
                          }}
                        />

                        {uploadError?.bank && (
                          <p className="text-danger">{uploadError?.bank}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                        <label>Password</label>
                        <input
                          id="password"
                          type="text"
                          value={uploadData?.password}
                          className="form-control"
                          onChange={handleUploadChange}
                        />

                        {uploadError?.password && (
                          <p className="text-danger">{uploadError?.password}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                        <label>
                          File <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          required
                        />
                        {file?.map((el, index) => (
                          <blockquote key={index}>
                            <p className="mb-0">{el?.name}</p>
                          </blockquote>
                        ))}
                        {uploadError?.file && (
                          <p className="text-danger">{uploadError?.file}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <button
                    type="reset"
                    className="btn btn-primary cancel me-2"
                    onClick={() => {
                      handleModalClose("#upload_bank_info");
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {uploadLoading ? <Spin /> : "Upload"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      }
    />
  );

  return <>{uploadBankStatement}</>;
};

BankStatementUpload.propTypes = {
  modalRef: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  uploadData: PropTypes.object.isRequired,
  bankDropdownList: PropTypes.array,
  setUploadData: PropTypes.func.isRequired,
  uploadError: PropTypes.object,
  selectRefs: PropTypes.object.isRequired,
  setUploadError: PropTypes.func.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  file: PropTypes.object,
  handleModalClose: PropTypes.func.isRequired,
  uploadLoading: PropTypes.bool,
};

export default BankStatementUpload;
