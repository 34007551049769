import {
  GET_LEDGERLIST_FAIL,
  GET_LEDGERLIST_REQUSET,
  GET_LEDGERLIST_SUCCESS,
  LEDGERLIST_INVOICE_RESET,
  LEDGERLISTSET_PAGE,
  POST_LEDGERLIST_REQUSET,
  POST_LEDGERLIST_SUCCESS,
  POST_LEDGERLIST_FAIL,
  POST_LEDGERLIST_RESET,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_RESET,
  GET_GROUPLIST_REQUSET,
  GET_GROUPLIST_SUCCESS,
  GET_GROUPLIST_FAIL,
  GET_LEDGER_GROUP_REQUSET,
  GET_LEDGER_GROUP_SUCCESS,
  GET_LEDGER_GROUP_FAIL,
  UPDATE_LEDGERLIST_REQUSET,
  UPDATE_LEDGERLIST_SUCCESS,
  UPDATE_LEDGERLIST_FAIL,
  UPDATE_LEDGERLIST_RESET,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL,
  GROUP_UPDATE_RESET,
  GROUP_DETAIL_REQUEST,
  GROUP_DETAIL_SUCCESS,
  GROUP_DETAIL_FAIL,
  GROUP_DETAIL_RESET,
  LEDGER_DETAIL_REQUEST,
  LEDGER_DETAIL_SUCCESS,
  LEDGER_DETAIL_FAIL,
  LEDGER_DETAIL_RESET,
  GET_LEDGER_LIST_REQUSET,
  GET_LEDGER_LIST_SUCCESS,
  GET_LEDGER_LIST_FAIL,
} from "../constants/ledgerListModuleConstant";

const initialState = {
  data: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const ledgerModuleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEDGERLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case GET_LEDGERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case GET_LEDGERLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case LEDGERLISTSET_PAGE:
      return { ...state, page: action.payload };
    case LEDGERLIST_INVOICE_RESET:
      return {};
    default:
      return state;
  }
};

export const postLedgerModuleListReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_LEDGERLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case POST_LEDGERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload.data,
      };
    case POST_LEDGERLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case POST_LEDGERLIST_RESET:
      return {};
    default:
      return state;
  }
};

export const updateLedgerModuleListReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LEDGERLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case UPDATE_LEDGERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPDATE_LEDGERLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_LEDGERLIST_RESET:
      return {};
    default:
      return state;
  }
};

export const groupListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GROUPLIST_REQUSET:
      return { loading: true };
    case GET_GROUPLIST_SUCCESS:
      return { loading: false, groupData: action.payload };
    case GET_GROUPLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_CREATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GROUP_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action?.payload,
      };
    case GROUP_CREATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GROUP_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const updateGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_UPDATE_REQUEST:
      return { ...state, loading: true, error: null };
    case GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action?.payload,
      };
    case GROUP_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GROUP_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const groupDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_DETAIL_REQUEST:
      return { ...state, loading: true, error: null };
    case GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action?.payload,
      };
    case GROUP_DETAIL_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GROUP_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const ledgerDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case LEDGER_DETAIL_REQUEST:
      return { ...state, loading: true, error: null };
    case LEDGER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action?.payload,
      };
    case LEDGER_DETAIL_FAIL:
      return { ...state, loading: false, error: action.payload };
    case LEDGER_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const ledgerGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LEDGER_GROUP_REQUSET:
      return { loading: true };
    case GET_LEDGER_GROUP_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_LEDGER_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ledgerListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LEDGER_LIST_REQUSET:
      return { loading: true };
    case GET_LEDGER_LIST_SUCCESS:
      return { loading: false, ledgerListData: action.payload };
    case GET_LEDGER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
