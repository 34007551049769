import { Spin } from "antd";
import Select from "react-select";
import React, { useEffect, useRef } from "react";
import CustomInput from "../_components/inputField/custominput";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getGroupList } from "../redux/actions/LedgerModuleAction";
import { useDispatch } from "react-redux";

const AddLedgerModal = ({
  addLedgerData,
  apiError,
  handleChange,
  handleModalClose,
  handleEditSubmit,
  loading,
  handleSelectChange,
}) => {
  const dispatch = useDispatch();

  const groupList = useSelector((state) => state?.groupList);
  const { groupData } = groupList;

  const selectRefs = useRef({});

  useEffect(() => {
    dispatch(getGroupList());
  }, []);

  const createLedger = (
    <div
      id="add_ledger"
      className="modal fade add-ledger-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "1050",
        }}
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h4 className="modal-title">Add Ledger</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <div className="row">
              {/* <form onSubmit={handleSubmit(handleEditSubmit)}> */}
              <div className="col-md-12">
                <div className="form-group-item border-0 mb-0">
                  <div className="row align-item-center">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      {/* <Controller
                            name="title"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <CustomInput
                                id="title"
                                type="text"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeholder="Title"
                                label="Title"
                                errorMsg={errors?.title?.message}
                                error={errors?.title}
                                // apiError={addError?.title}
                                autoComplete="false"
                                required={true}
                              />
                            )}
                          />
                          <Controller
                            name="type"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <CustomInput
                                id="type"
                                type="text"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeholder="Type"
                                label="Type"
                                errorMsg={errors?.type?.message}
                                error={errors?.type}
                                // apiError={addError?.type}
                                autoComplete="false"
                                required={true}
                              />
                            )}
                          /> */}
                      <CustomInput
                        id="title"
                        type="text"
                        value={addLedgerData?.title}
                        onChange={handleChange}
                        placeholder="Title"
                        label="Title"
                        required={true}
                        apiError={apiError?.title}
                      />
                      <br />
                      <div className="row align-item-center">
                        <div className="col-lg-12 col-md-6 col-sm-12">
                          <label>Group</label>

                          <Select
                            id="group"
                            ref={(el) => (selectRefs.current["group"] = el)}
                            value={addLedgerData?.group}
                            options={
                              groupData?.length &&
                              groupData?.map((option) => ({
                                value: option.id,
                                label: option.title,
                              }))
                            }
                            onChange={(selectedOption) =>
                              handleSelectChange("group", selectedOption)
                            }
                          />

                          {apiError?.group && (
                            <p className="text-danger">{apiError?.group}</p>
                          )}
                        </div>
                      </div>
                      <br />
                      {/* <CustomInput
                            id="type"
                            type="text"
                            value={addLedgerData?.type}
                            onChange={handleChange}
                            placeholder="Type"
                            label="Type"
                            required={true}
                            apiError={apiError?.type}
                          />
                           <br/> */}
                      <CustomInput
                        id="description"
                        type="text"
                        value={addLedgerData?.description}
                        onChange={handleChange}
                        placeholder="Description"
                        label="Description"
                        apiError={apiError?.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    type="reset"
                    className="btn btn-primary cancel me-2"
                    onClick={() => handleModalClose("#add_ledger")}
                  >
                    Cancel
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-primary"
                    onClick={handleEditSubmit}
                  >
                    {loading ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{createLedger}</>;
};

AddLedgerModal.propTypes = {
  addLedgerData: PropTypes.object,
  apiError: PropTypes.object,
  handleChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  loading: PropTypes.bool,
  handleSelectChange: PropTypes.func,
};

export default AddLedgerModal;
