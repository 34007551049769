/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Table } from "antd";

import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getReportList, setPage } from "../../redux/actions/ReportAction";

const DataProcessing = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state?.dataProcessingReport);
  const { dataListLoading, dataProcessing, page, perPage } = dataList;

  const columns = [
    {
      title: "Model",
      dataIndex: "model",
      sorter: (a, b) => a.model.length - b.model.length,
    },
    {
      title: "Description/Period",
      dataIndex: "description",
      sorter: (a, b) => a?.description?.length - b?.description?.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      sorter: (a, b) => a?.action?.length - b?.action?.length,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      sorter: (a, b) => a?.timestamp?.length - b?.timestamp?.length,
    },
  ];

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    dispatch(getReportList(page, perPage));
  }, [page, perPage]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Reports</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Data Processing Logs
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-55">
                      <Table
                        className="table"
                        loading={dataListLoading}
                        pagination={{
                          total: dataProcessing?.count,
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={dataProcessing?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DataProcessing;
