import {
  GET_SUBSCRIPTION_HISTORY_LIST_FAIL,
  GET_SUBSCRIPTION_HISTORY_LIST_REQUEST,
  GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS,
  GET_SUBSCRIPTION_PLANS_LIST_FAIL,
  GET_SUBSCRIPTION_PLANS_LIST_REQUEST,
  GET_SUBSCRIPTION_PLANS_LIST_SUCCESS,
} from "./membershipConstant";

export const SubscriptionHistoryListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_HISTORY_LIST_REQUEST:
      return { loading: true };
    case GET_SUBSCRIPTION_HISTORY_LIST_SUCCESS:
      return { loading: false, listData: action.payload };
    case GET_SUBSCRIPTION_HISTORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SubscriptionPlanListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_LIST_REQUEST:
      return { loading: true };
    case GET_SUBSCRIPTION_PLANS_LIST_SUCCESS:
      return { loading: false, listData: action.payload };
    case GET_SUBSCRIPTION_PLANS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
