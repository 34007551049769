import React from "react";
import PropTypes from "prop-types";

export default function CreateModal({ id, modalRef, show, title, modalbody }) {
  return (
    <div
      id={id}
      className="modal fade"
      tabIndex={-1}
      ref={modalRef}
      role="dialog"
      aria-hidden="true"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">{modalbody}</div>
        </div>
      </div>
    </div>
  );
}

CreateModal.propTypes = {
  id: PropTypes.string.isRequired,
  modalRef: PropTypes.object,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  modalbody: PropTypes.node.isRequired,
};
