import React from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  apInvoiceAddVendor,
  createExistingVendor,
} from "../../redux/actions/VendorAction";
import {
  VENDOR_ADD_RESET,
  VENDOR_SEARCH_RESET,
} from "../../redux/constants/VendorConstant";
import { Controller } from "react-hook-form";
import { Spin } from "antd";
import CreateModal from "../../_components/modalbox/createmodal";
import CustomInput from "../../_components/inputField/custominput";
import PropTypes from "prop-types";

const AddVendor = ({
  show,
  modalRef,
  handleSubmit,
  addVendorData,
  setIsDisable,
  isExistingVendor,
  setIsExistingVendor,
  setExistingVendorError,
  setSelectedVendor,
  selectedVendor,
  vendorSearchList,
  selectRefs,
  inputValue,
  existingVendorError,
  setInputValue,
  debouncedFetch,
  apiError,
  setApiError,
  setAddVendorData,
  errors,
  isDisable,
  addExistingVendorLoading,
  control,
  loading,
}) => {
  const dispatch = useDispatch();
  const addVendorSubmit = (data) => {
    if (!isExistingVendor) {
      const postData = {
        // ...data,
        name: data?.name ? data?.name : "",
        vendor_code: addVendorData?.vendor_code
          ? addVendorData?.vendor_code
          : null,
        address: data?.address ? data?.address : "",
        // zip_code: data?.zip_code ? data?.zip_code : "",
        pan_number: addVendorData?.pan_number
          ? addVendorData?.pan_number
          : null,
        gstin_number: data?.gstin_number ? data?.gstin_number : "",
        state: data?.state ? data?.state : "",
        phone: addVendorData?.phone ? addVendorData?.phone : null,
        contact_name: addVendorData?.contact_name
          ? addVendorData?.contact_name
          : null,
        email: addVendorData?.email ? addVendorData?.email : null,
        msme: addVendorData?.msme ? addVendorData?.msme : null,
        bank_name: addVendorData?.bank_name ? addVendorData?.bank_name : null,
        branch_name: addVendorData?.branch_name
          ? addVendorData?.branch_name
          : null,
        account_number: addVendorData?.account_number
          ? addVendorData?.account_number
          : null,
        ifsc_code: addVendorData?.ifsc_code ? addVendorData?.ifsc_code : "",
        swift: addVendorData?.swift ? addVendorData?.swift : "",
        extras: {
          ap_invoice_id: addVendorData?.ap_invoice_id,
        },
      };

      dispatch(apInvoiceAddVendor(postData));
      setIsDisable(false);
    }
  };

  const handleCheckBoxChange = (e) => {
    const isChecked = e.target.checked;
    setIsExistingVendor(isChecked);
    if (!isChecked) {
      setExistingVendorError({});
      setSelectedVendor(null);
      dispatch({ type: VENDOR_SEARCH_RESET });
    } else {
      dispatch({ type: VENDOR_ADD_RESET });
    }
  };

  const handleSelectChange = (selectedVendor) => {
    setSelectedVendor(selectedVendor);
    setExistingVendorError((prevState) => ({
      ...prevState,
      selectedVendor: "",
    }));
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, ""); // Example to clean input
    setInputValue(inputValue);
    if (inputValue) {
      debouncedFetch(inputValue);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddVendorData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
    setIsDisable(true);
    // dispatch({ type: VENDOR_ADD_RESET });
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setAddVendorData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
    setIsDisable(true);
    // setIsChanged(true);
  };

  const handleAddExistingVendor = (event) => {
    event.preventDefault();
    const id = addVendorData.ap_invoice_id;
    const errors = {};

    if (!selectedVendor?.value) {
      errors["selectedVendor"] = "Please select a vendor";
      selectRefs.current["selectedVendor"].focus();
      setExistingVendorError(errors);
    } else {
      dispatch(createExistingVendor(selectedVendor?.value, id));
    }
  };

  const createVendor = (
    <CreateModal
      id="add_vendor"
      modalRef={modalRef}
      show={show}
      title="Create Vendor"
      modalbody={
        <>
          <form onSubmit={handleSubmit(addVendorSubmit)}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <div className="col-md-10">
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox"
                            onChange={handleCheckBoxChange}
                            checked={isExistingVendor}
                          />{" "}
                          Existing Vendor
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {isExistingVendor && (
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <Select
                        id="selectedVendor"
                        value={selectedVendor}
                        options={vendorSearchList?.vendorSearchList?.results?.map(
                          (option) => ({
                            value: option.id,
                            label: option.name,
                          })
                        )}
                        ref={(el) =>
                          (selectRefs.current["selectedVendor"] = el)
                        }
                        onChange={handleSelectChange}
                        onInputChange={handleInputChange}
                        inputValue={inputValue}
                      />
                      {existingVendorError?.selectedVendor && (
                        <p className="text-danger">
                          {existingVendorError?.selectedVendor}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!isExistingVendor && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="name"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e);
                              }}
                              placeholder="Name"
                              label="Name"
                              errorMsg={errors?.name?.message}
                              error={errors?.name}
                              apiError={apiError?.name}
                              autoComplete="false"
                              required={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="email"
                          type="email"
                          value={addVendorData?.email}
                          onChange={handleCommonChange}
                          placeholder="Email"
                          label="Email"
                          apiError={apiError?.email}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="vendor_code"
                          type="text"
                          value={addVendorData?.vendor_code}
                          onChange={handleCommonChange}
                          placeholder="Vendor Code"
                          label="Vendor Code"
                          apiError={apiError?.vendor_code}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="pan_number"
                          type="text"
                          value={addVendorData?.pan_number}
                          onChange={handleCommonChange}
                          placeholder="PAN : 10 Digits"
                          label="PAN"
                          apiError={apiError?.pan_number}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-block mb-3">
                          <label>
                            Address<span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="address"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <textarea
                                className={`form-control  ${
                                  errors?.address ? "error-input" : ""
                                }`}
                                id="address"
                                type="text"
                                placeholder="Address"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  handleChange(e);
                                }}
                                autoComplete="false"
                              />
                            )}
                          />
                          {apiError?.address && (
                            <p className="text-danger">{apiError?.address}</p>
                          )}
                          {errors?.address?.message && (
                            <p className="text-danger">
                              {errors?.address?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <Controller
                          name="state"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="state"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e);
                              }}
                              placeholder="State"
                              label="State"
                              errorMsg={errors?.state?.message}
                              error={errors?.state}
                              apiError={apiError?.state}
                              autoComplete="false"
                              required={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                   
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <Controller
                          name="gstin_number"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="gstin_number"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleChange(e);
                              }}
                              placeholder="GSTIN"
                              label="GSTIN"
                              errorMsg={errors?.gstin_number?.message}
                              error={errors?.gstin_number}
                              apiError={apiError?.gstin_number}
                              autoComplete="false"
                              required={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="msme"
                          type="text"
                          value={addVendorData?.msme}
                          onChange={handleCommonChange}
                          placeholder="MSME"
                          label="MSME"
                          apiError={apiError?.msme}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="contact"
                          type="text"
                          value={addVendorData?.contact}
                          onChange={handleCommonChange}
                          placeholder="Contact name"
                          label="Contact name"
                          apiError={apiError?.contact}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="phone"
                          type="text"
                          value={addVendorData?.phone}
                          onChange={handleCommonChange}
                          placeholder="Number with country code"
                          label="Phone"
                          apiError={apiError?.phone}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="bank_name"
                          type="text"
                          value={addVendorData?.bank_name}
                          onChange={handleCommonChange}
                          placeholder="Bank Name"
                          label="Bank Name"
                          apiError={apiError?.bank_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="account_number"
                          type="text"
                          value={addVendorData?.account_number}
                          onChange={handleCommonChange}
                          placeholder="A/C No"
                          label="A/C No"
                          apiError={apiError?.account_number}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="branch_name"
                          type="text"
                          value={addVendorData?.branch_name}
                          onChange={handleCommonChange}
                          placeholder="Bank branch"
                          label="Bank branch"
                          apiError={apiError?.branch_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="ifsc_code"
                          type="text"
                          value={addVendorData?.ifsc_code}
                          onChange={handleCommonChange}
                          placeholder="IFSC"
                          label="IFSC"
                          apiError={apiError?.ifsc_code}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <CustomInput
                          id="swift"
                          type="text"
                          value={addVendorData?.swift}
                          onChange={handleCommonChange}
                          placeholder="SWIFT"
                          label="SWIFT"
                          apiError={apiError?.swift}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              {/* <button type="reset" className="btn btn-primary cancel me-2">
                    Edit
                  </button> */}
              {!isExistingVendor ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDisable}
                >
                  {loading ? <Spin /> : "Save"}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={addExistingVendorLoading}
                  onClick={handleAddExistingVendor}
                >
                  {addExistingVendorLoading ? <Spin /> : "Save"}
                </button>
              )}
            </div>
          </form>
        </>
      }
    />
  );

  return <>{createVendor}</>;
};

AddVendor.propTypes = {
  show: PropTypes.bool.isRequired,
  modalRef: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  addVendorData: PropTypes.object.isRequired,
  setIsDisable: PropTypes.func.isRequired,
  isExistingVendor: PropTypes.bool.isRequired,
  setIsExistingVendor: PropTypes.func.isRequired,
  setExistingVendorError: PropTypes.func.isRequired,
  setSelectedVendor: PropTypes.func.isRequired,
  selectedVendor: PropTypes.object,
  vendorSearchList: PropTypes.object.isRequired,
  selectRefs: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  existingVendorError: PropTypes.object.isRequired,
  setInputValue: PropTypes.func.isRequired,
  debouncedFetch: PropTypes.func.isRequired,
  apiError: PropTypes.object,
  setApiError: PropTypes.func.isRequired,
  setAddVendorData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isDisable: PropTypes.bool.isRequired,
  addExistingVendorLoading: PropTypes.bool,
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default AddVendor;
