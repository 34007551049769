export const currencyTypes = [
  {
    id: "INR",
    name: "INR",
  },
  {
    id: "AUD",
    name: "AUD",
  },
  {
    id: "AED",
    name: "AED",
  },
  {
    id: "CAD",
    name: "CAD",
  },
  {
    id: "CNY",
    name: "CNY",
  },
  {
    id: "EUR",
    name: "EUR",
  },
  {
    id: "GPB",
    name: "GPB",
  },
  {
    id: "JPY",
    name: "JPY",
  },
  {
    id: "SAR",
    name: "SAR",
  },
  {
    id: "USD",
    name: "USD",
  },
  {
    id: "ZAR",
    name: "ZAR",
  },
];

export const accountTypeList = [
  { id: "", text: "All" },
  { id: "Bank", text: "Bank" },
  { id: "CreditCard", text: "CreditCard" },
];

export const transactionTypesList = [
  {
    label: "Vendor Payment",
    value: "vendor_payment",
  },
  {
    label: "Customer Payment",
    value: "customer_payment",
  },
  {
    label: "Other Income",
    value: "other_income",
  },
  {
    label: "Expense",
    value: "expense",
  },
];
