import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import { X } from 'lucide-react';
import { HTTP } from '../redux/api';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SubscriptionBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state) => state.userSignin);
  const { userInfo } = userData;

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        // const response = await HTTP.get('/payment/active/');
        // console.log(response);
        const response = await HTTP.get("/payment/active/", {
            headers: {
              Authorization: `Bearer ${userInfo}`,
            },
          });
          const data = response.data;
          console.log(data,'response')        
        // Show banner only if subscription is inactive AND it's not a recent registration
        setShowBanner(!data.active && !data.recent_registration);
      } catch (error) {
        console.error('Error checking subscription:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscription();
  }, []);

  if (isLoading || !showBanner) return null;

  const bannerStyle = {
    marginBottom: '20px',
    borderRadius: '8px',
    padding: '12px 24px',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  };

  const messageStyle = {
    fontSize: '16px',
    fontWeight: '500',
  };

  const actionContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  };

  const closeButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    marginLeft: '8px',
  };

  return (
    <Alert
      style={bannerStyle}
      type="error"
      message={
        <div style={containerStyle}>
          <span style={messageStyle}>
            Your subscription has expired
          </span>
          <div style={actionContainerStyle}>
          <Link to='/membership-plans'>
            <Button
              type="primary"
            //   onClick={() => window.location.href = '/membership-plans'}
              style={{ height: '32px' }}
            >
              Renew Now
            </Button>
            </Link>  
            <button
              onClick={() => setShowBanner(false)}
              style={closeButtonStyle}
              aria-label="Close"
            >
              <X size={20} />
            </button>
          </div>
        </div>
      }
      banner
    />
  );
};

export default SubscriptionBanner;