import React from "react";
import PropTypes from "prop-types";
// import Select from "react-select";
import CustomSelect from "../../../customers/customSelect";
import AddLedgerModal from "../../../customers/addLedgerModal";
// import { Select } from "antd";

const RecurringTable = ({
  id,
  // ledgerData,
  // handleItemChange,
  // itemsError,
  setIsChanged,
  // setLedgerData,
  editable,
  setEditable,
  // expenseList,
  // ledgerListLoading,
  data,
  setData,
  handleCellChange,
  handleSelectCellChange,
  handleDoubleClick,
  footerData,
  // setFooterData,
  editModal,
  setAddLedgerData,
  addLedgerData,
  apiError,
  setApiError,
  setIsModalVisible,
  isModalVisible,
  postLedgerLoading,
  recurringInvoiceSubmit,
  handleSubmit,
  handleModalClose,
  addLedgerSubmit,
  handleLedgerPopupScroll,
  ledgerListLoading,
  ledgerListData
}) => {
  const handleLedgerChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleLedgerSelectChange = (id, selectedOption) => {
    setAddLedgerData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  console.log(ledgerListData,'ledgerListData');
  

  const renderCell = (rowId, field, value, index) => {
    if (editable.rowId === rowId && editable.field === field) {
      if (field === "ledger") {
        return (
          <>
           
            <CustomSelect
              id={"ledger"}
              value={value}
              onChange={(selectedOption) => {
                handleSelectCellChange(selectedOption, "ledger", rowId, index);
              }}
              options={
                ledgerListData?.length
                  ? ledgerListData.reduce((acc, current) => {
                      const x = acc.find(item => item.id === current.id);
                      if (!x) {
                        acc.push(current);
                      }
                      return acc;
                    }, [])
                  : []
              }
              addLedgerData={addLedgerData}
              setAddLedgerData={setAddLedgerData}
              handleSubmit={handleSubmit}
              handleEditSubmit={recurringInvoiceSubmit}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              handleModalClose={handleModalClose}
              setApiError={setApiError}
              apiError={apiError}
              onPopupScroll={handleLedgerPopupScroll}
              loading={ledgerListLoading}
            />
            {/* )} */}
          </>
        );
      }
      //  else if (field === "tds_amount") {
      //   return <input type={"text"} value={value} readOnly />;
      // }
      else {
        return (
          <input
            style={{ cursor: "pointer" }}
            type={field === "description" ? "text" : "number"}
            value={value}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index)
            }
            required
            disabled={
              editModal
                ? field === "amount" ||
                  field === "gst_amount" ||
                  field === "total"
                : false
            }
            onBlur={() => setEditable({})}
            // autoFocus
          />
        );
      }
    }
    if (field === "ledger") {
      return (
        <>
          {/* {id === "addrecurring_invoice" ? (
            <Select
              value={value}
              options={
                expenseList?.length
                  ? expenseList?.map((option) => ({
                      value: option.id,
                      label: option.title,
                    }))
                  : []
              }
              onChange={(selectedOption) => {
                handleSelectCellChange(selectedOption, "ledger", rowId, index);
              }}
            />
          ) : ( */}
          <CustomSelect
            id={"ledger"}
            value={value}
            onChange={(selectedOption) => {
              handleSelectCellChange(selectedOption, "ledger", rowId, index);
            }}
            options={
              ledgerListData?.length
                ? ledgerListData
                    .filter(item => item !== null)  // Remove null items from the array
                    .reduce((acc, current) => {
                      const x = acc.find(item => item.id === current.id);
                      if (!x) {
                        acc.push(current);
                      }
                      return acc;
                    }, [])
                : []
            }
            
            
            addLedgerData={addLedgerData}
            setAddLedgerData={setAddLedgerData}
            handleSubmit={handleSubmit}
            handleEditSubmit={recurringInvoiceSubmit}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            handleModalClose={handleModalClose}
            setApiError={setApiError}
            apiError={apiError}
            onPopupScroll={handleLedgerPopupScroll}
            loading={ledgerListLoading}
          />
          {/* )} */}
        </>
      );
    }
    // else if (field === "tds_amount") {
    //   return <input type={"text"} value={value} readOnly />;
    // }
    else if (editModal) {
      return (
        <input
          type={field === "description" ? "text" : "number"}
          value={value}
          required
          disabled={
            field === "amount" || field === "gst_amount" || field === "total"
          }
          readOnly
          onDoubleClick={() => handleDoubleClick(rowId, field, index)}
        />
      );
    } else {
      return (
        <input
          type={field === "description" ? "text" : "number"}
          value={value}
          required
          disabled={
            field === "amount" || field === "gst_amount" || field === "total"
          }
          // readOnly
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index)
          }
          // onDoubleClick={() => handleDoubleClick(rowId, field)}
        />
      );
    }
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        description: "",
        quantity: "",
        rate: "",
        gst_rate: "",
        gst_amount: "",
        amount: "",
        total: "",
        taxes: [],
      },
    ]);
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    if (id !== "addrecurring_invoice") {
      setIsChanged(true);
    }
    const filterData = data?.filter((el, index) => index !== tabIndex);
    setData(filterData);
  };

  return (
    <>
      <div className="complex-invoice-table">
        <div
          className="ag-theme-alpine"
          style={{ width: "100%", minHeight: "300px" }}
        >
          <div className="tableHscroll">
            <table className="apedittable">
              <thead>
                <tr>
                  <>
                    <td align="center">Sl No.</td>
                    <td>Ledger Name</td>
                    <td>Description</td>
                    <td>Unit</td>
                    <td>Unit Price</td>
                    <td>Base Amount</td>
                    <td>GST Rate</td>
                    <td>GST Amount</td>
                    <td>Total</td>
                    <td style={{ width: "auto" }}></td>
                  </>
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr key={row.id}>
                    <>
                      <td align="center"> {index + 1}</td>
                      <td className="customtableSelect">
                        {renderCell(row.id, "ledger", row?.ledger, index)}
                      </td>
                      <td>
                        {renderCell(
                          row.id,
                          "description",
                          row.description,
                          index
                        )}
                      </td>
                      <td>
                        {renderCell(row.id, "quantity", row.quantity, index)}
                      </td>
                      <td>{renderCell(row.id, "rate", row.rate, index)}</td>
                      <td>{renderCell(row.id, "amount", row.amount, index)}</td>
                      <td>
                        {renderCell(row.id, "gst_rate", row.gst_rate, index)}
                      </td>
                      <td>
                        {renderCell(
                          row.id,
                          "gst_amount",
                          row.gst_amount,
                          index
                        )}
                      </td>
                      <td>{renderCell(row.id, "total", row.total, index)}</td>
                      <td className="tableEditBtns">
                        <button
                          className="btn btn-default btn-xs add"
                          onClick={(event) => handleRemove(event, index)}
                        >
                          <i className="fas fa-trash" />
                        </button>
                      </td>
                    </>
                  </tr>
                ))}
                {/* {editModal && ( */}
                <tr>
                  <td
                    colSpan={10}
                    style={{
                      textAlign: "right",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      className="btn btn-greys btn-xs add"
                      onClick={handleAddClick}
                    >
                      <i className="far fa-add" />
                    </button>
                  </td>
                </tr>
                {/* )} */}
                {/* {editModal && ( */}
                <>
                  <tr>
                    <td colSpan={10}></td>
                  </tr>
                  <tr className="rowStickty">
                    <>
                      <td colSpan={7}></td>
                      <td align="left">
                        <strong>Base Amount</strong>
                      </td>
                      <td align="right" colSpan={2}>
                        {footerData?.total_base_amount
                          ? footerData?.total_base_amount
                          : 0}
                      </td>
                    </>
                  </tr>
                  {footerData?.gst_section &&
                    Object.keys(footerData?.gst_section)?.map((el, index) => {
                      return (
                        <tr key={index} className="rowStickty">
                          <>
                            <td colSpan={7}></td>
                            <td align="left">
                              <strong>{el}</strong>
                            </td>
                            <td align="right" colSpan={2}>
                              {footerData?.gst_section
                                ? footerData["gst_section"][el]
                                : ""}
                            </td>
                          </>
                        </tr>
                      );
                    })}
                  <tr className="rowStickty">
                    <>
                      <td colSpan={7}></td>
                      <td align="left">
                        <strong>Total Tax</strong>
                      </td>
                      <td align="right" colSpan={2}>
                        {footerData?.total_tax ? footerData?.total_tax : 0}
                      </td>
                    </>
                  </tr>
                  <tr className="rowStickty">
                    <>
                      <td colSpan={7}></td>
                      <td align="left">
                        <strong>Total Amount</strong>
                      </td>
                      <td align="right" colSpan={2}>
                        {footerData?.total_amount}
                      </td>
                    </>
                  </tr>
                  <tr className="rowStickty">
                    <>
                      <td colSpan={7}></td>
                      <td align="left">
                        <strong>Amount Payable</strong>
                      </td>
                      <td align="right" colSpan={2}>
                        {footerData?.amount_payable}
                      </td>
                    </>
                  </tr>
                </>
                {/* )} */}
              </tbody>
            </table>
          </div>
          <AddLedgerModal
            addLedgerData={addLedgerData}
            apiError={apiError}
            handleChange={handleLedgerChange}
            handleModalClose={handleModalClose}
            handleEditSubmit={addLedgerSubmit}
            loading={postLedgerLoading}
            handleSelectChange={handleLedgerSelectChange}
          />
          {/* {editModal && (
            <div className="tableHscroll">
              <table className="apedittableInvoice">
                <thead>
                  <tr>
                    <>
                      <td colSpan="3">Debit</td>
                      <td colSpan={3}>Credit</td>
                    </>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <>
                      <td colSpan={3}>
                        <table>
                          <thead>
                            <tr>
                              <>
                                <td>Date</td>
                                <td>Ledger Name</td>
                                <td>Amount</td>
                              </>
                            </tr>
                          </thead>
                          <tbody>
                            {footerData?.suggested_entry?.debit &&
                              footerData?.suggested_entry?.debit?.ledgers?.map(
                                (el, index) => {
                                  return (
                                    <tr key={index}>
                                      <>
                                        <td>
                                          {index === 0
                                            ? footerData?.suggested_entry?.debit
                                                ?.date
                                            : ""}
                                        </td>
                                        <td>{el?.ledger}</td>
                                        <td>{el?.amount}</td>
                                      </>
                                    </tr>
                                  );
                                }
                              )}
                            <tr>
                              <>
                                <td></td>
                                <td>
                                  <strong>Rates & Taxes</strong>
                                </td>
                                <td>
                                  <strong>Tax Amount</strong>
                                </td>
                              </>
                            </tr>
                            {footerData?.suggested_entry?.debit?.gst &&
                              Object.keys(
                                footerData?.suggested_entry?.debit?.gst
                              )?.map((el, index) => {
                                return (
                                  <tr key={index}>
                                    <>
                                      <td></td>
                                      <td>{el}</td>
                                      <td>
                                        {footerData?.suggested_entry?.debit?.gst
                                          ? footerData["suggested_entry"][
                                              "debit"
                                            ]["gst"][el]
                                          : ""}
                                      </td>
                                    </>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </td>
                      <td colSpan={3}>
                        <table>
                          <thead>
                            <tr>
                              <>
                                <td>Date</td>
                                <td>Ledger Name</td>
                                <td>Amount</td>
                              </>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <>
                                <td>
                                  {footerData?.suggested_entry?.credit?.date
                                    ? footerData?.suggested_entry?.credit?.date
                                    : ""}
                                </td>
                                <td>
                                  {footerData?.suggested_entry?.credit?.ledger
                                    ? footerData?.suggested_entry?.credit
                                        ?.ledger
                                    : ""}
                                </td>
                                <td>
                                  {footerData?.suggested_entry?.credit?.amount
                                    ? footerData?.suggested_entry?.credit
                                        ?.amount
                                    : ""}
                                </td>
                              </>
                            </tr>
                            <tr>
                              <>
                                <td></td>
                                <td>
                                  <b>TDS Under Section</b>
                                </td>
                                <td>
                                  <b>TDS Amount</b>
                                </td>
                              </>
                            </tr>
                            {footerData?.suggested_entry?.credit?.tds &&
                              Object.keys(
                                footerData?.suggested_entry?.credit?.tds
                              )?.map((el, index) => {
                                return (
                                  <tr key={index}>
                                    <>
                                      <td></td>
                                      <td>{el}</td>
                                      <td>
                                        {footerData?.suggested_entry?.credit
                                          ?.tds
                                          ? footerData["suggested_entry"][
                                              "credit"
                                            ]["tds"][el]
                                          : ""}
                                      </td>
                                    </>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </td>
                    </>
                  </tr>
                  <tr>
                    <>
                      <td></td>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        {footerData?.suggested_entry?.debit
                          ? footerData?.suggested_entry?.debit?.total
                          : ""}
                      </td>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        {footerData?.suggested_entry?.credit
                          ? footerData?.suggested_entry?.credit?.total
                          : ""}
                      </td>
                    </>
                  </tr>
                </tbody>
              </table>
            </div>
          )} */}
        </div>
      </div>
      {/* <table className="apedittable">
        <thead>
          <tr>
            <td>Sl No.</td>
            <td>Description of supply</td>
            <td>Quantity</td>
            <td>Rate</td>
            <td>Gst Rate</td>
            <td>Gst Amount</td>
            <td>Amount</td>
            <td>Total</td>
            <td>Unit</td>

            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {ledgerData?.map((el, index) => {
            return (
              <tr key={index}>
                <td>{index}</td>
                <td>
                  <input
                    id="description"
                    type="text"
                    required
                    value={el?.description}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.description && (
                    <p className="text-danger">
                      {itemsError[index]?.description}
                    </p>
                  )}
                </td>
                <td>
                  <input
                    id="quantity"
                    type="text"
                    required
                    value={el?.quantity}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.quantity && (
                    <p className="text-danger">{itemsError[index]?.quantity}</p>
                  )}
                </td>
                <td>
                  <input
                    id="rate"
                    type="text"
                    required
                    value={el?.rate}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.rate && (
                    <p className="text-danger">{itemsError[index]?.rate}</p>
                  )}
                </td>
                <td>
                  <input
                    id="gst_rate"
                    type="text"
                    required
                    value={el?.gst_rate}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.gst_rate && (
                    <p className="text-danger">{itemsError[index]?.gst_rate}</p>
                  )}
                </td>
                <td>
                  <input
                    id="gst_amount"
                    type="text"
                    required
                    value={el?.gst_amount}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.gst_amount && (
                    <p className="text-danger">
                      {itemsError[index]?.gst_amount}
                    </p>
                  )}
                </td>
                <td>
                  <input
                    id="amount"
                    type="text"
                    required
                    value={el?.amount}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.amount && (
                    <p className="text-danger">{itemsError[index]?.amount}</p>
                  )}
                </td>
                <td>
                  <input
                    id="total"
                    type="text"
                    required
                    value={el?.total}
                    onChange={(e) => handleItemChange(e, index)}
                  />
                  {itemsError[index] && itemsError[index]?.total && (
                    <p className="text-danger">{itemsError[index]?.total}</p>
                  )}
                </td>
                <td>
                  <input
                    id="amount"
                    type="text"
                    required
                    value={el?.amount}
                    onChange={(e) => handleItemChange(e, index)}
                  />{" "}
                  {itemsError[index] && itemsError[index]?.amount && (
                    <p className="text-danger">{itemsError[index]?.amount}</p>
                  )}
                </td>
                <td className="tableEditBtns">
                  {index === ledgerData?.length - 1 && (
                    <button
                      className="btn btn-default btn-xs delete"
                      onClick={handleAddClick}
                    >
                      <i className="far fa-add" />
                    </button>
                  )}
                  {index !== ledgerData?.length - 1 && (
                    <button
                      className="btn btn-default btn-xs add"
                      onClick={(event) => handleRemove(event, index)}
                    >
                      <i className="fas fa-trash" />
                    </button>
                  )}
                </td>
              </tr>
            );
          })}

          <tr>
            <td colSpan={9}></td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <strong>CGST Output</strong>
            </td>
            <td>
              <input value="HSN/SAC" disabled />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <strong>SGST Output</strong>
            </td>
            <td>
              <input value="HSN/SAC" disabled />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <strong>IGST Output</strong>
            </td>
            <td>
              <input value="HSN/SAC" disabled />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <strong>Select Tax Ledger</strong>
            </td>
            <td>
              <input value="HSN/SAC" disabled />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <strong>Select Tax Ledger</strong>
            </td>
            <td>
              <input value="HSN/SAC" disabled />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={9}></td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <strong>Total</strong>
            </td>
            <td>
              <input value="HSN/SAC" disabled />
            </td>
            <td>&nbsp;</td>
          </tr> */}
      {/* </tbody> */}
      {/* </table> */}
    </>
  );
};

RecurringTable.propTypes = {
  id: PropTypes.string.isRequired,
  ledgerData: PropTypes.array.isRequired,
  handleItemChange: PropTypes.func.isRequired,
  itemsError: PropTypes.array.isRequired,
  setIsChanged: PropTypes.bool,
  setLedgerData: PropTypes.func.isRequired,
  editable: PropTypes.object,
  setEditable: PropTypes.func,
  // expenseList: PropTypes.array,
  ledgerListLoading: PropTypes.bool,
  data: PropTypes.array,
  setData: PropTypes.func,
  handleCellChange: PropTypes.func,
  handleSelectCellChange: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  footerData: PropTypes.object,
  setFooterData: PropTypes.func,
  editModal: PropTypes.func,
  setAddLedgerData: PropTypes.func,
  addLedgerData: PropTypes.object,
  apiError: PropTypes.object,
  setApiError: PropTypes.func,
  setIsModalVisible: PropTypes.func,
  isModalVisible: PropTypes.bool,
  postLedgerLoading: PropTypes.bool,
  recurringInvoiceSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleModalClose: PropTypes.func,
  addLedgerSubmit: PropTypes.func,
  handleLedgerPopupScroll: PropTypes.func,
  ledgerListData:PropTypes.func
};

export default RecurringTable;
