/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { AlertCircle, X } from 'lucide-react';

const fieldMapping = {
  posting_ledger: 'Ledger',
  ledger: 'Ledger',
  quantity: 'Unit',
  description: 'Description',
  rate: 'Unit Price',
  tds_ledger: 'TDS Ledger',
  amount: 'Base Amount',
  gst_rate: 'GST Rate',
  gst_amount: 'GST Amount',
  total: 'Total',
  tds_amount: 'TDS Amount',
  total_base_amount: 'Base Amount',
  total_tax: 'Total Tax',
  total_amount: 'Total Amount',
  total_tds_amount: 'TDS Total Amount'
};

const getDisplayName = (key) => {
  return fieldMapping[key] || key;
};

const InvoiceErrorDisplay = ({ itemsError, onClose }) => {
  if (!itemsError?.length) return null;
  
  const hasErrors = itemsError.some(row => Object.keys(row).length > 0);
  if (!hasErrors) return null;

  return (
    <div className="invoice-error-panel">
      <div className="error-panel-header">
        <div className="error-title">
          <AlertCircle className="error-icon" />
          <span>Please Correct These Errors</span>
        </div>
        <button type='button' onClick={onClose} className="close-button">
          <X size={18} />
        </button>
      </div>
      <div className="error-panel-content">
        {itemsError.map((rowError, index) => {
          if (Object.keys(rowError).length === 0) return null;
          return (
            <div key={index} className="error-row">
              <div className="error-row-header">Item {index + 1}</div>
              <ul className="error-list">
                {Object.entries(rowError).map(([field, errors]) => (
                  <li key={field} className="error-item">
                    <span className="error-field">{getDisplayName(field)}:</span>
                    <span className="error-message">{errors[0]}</span>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

InvoiceErrorDisplay.propTypes = {
  itemsError: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
  ),
  onClose: PropTypes.func
};

export default InvoiceErrorDisplay;