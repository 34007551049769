/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InvoiceLogo1 } from "../_components/Imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import { useDispatch } from "react-redux";
import { signin } from "../redux/actions/UserAction";
import { useSelector } from "react-redux";
import { Spin, message } from "antd";

const schema = yup.object({
  username: yup
    .string()
    .required("Email is required")
    .trim(),
  password: yup
    .string()
    .min(1)
    .max(20)
    .required("Password is required")
    .trim(),
});

const Login = (props) => {
  const [eye, seteye] = useState(true);
  const userData = useSelector((state) => state.userSignin);
  const { userInfo, loading, error, refresh } = userData;
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const inputValues = {
    username: "",
    password: "",
  };

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: inputValues,
  });

  const onSubmit = (data) => {
    dispatch(signin(data));
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  useEffect(() => {
    if (userInfo && refresh) {
      props.history.push("index");
    }
    if (error) {
      messageApi.open({
        type: "error",
        content: error?.detail,
        duration: 5,
      });
    }
  }, [userInfo, refresh, error?.detail]);

  return (
    <>
      <div className="main-wrapper login-body">
        {contextHolder}

        <div className="login-wrapper">
          <div className="container">
            <img
              className="img-fluid logo-dark mb-2"
              src={InvoiceLogo1}
              alt="Logo"
            />
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Login</h1>
                  <p className="account-subtitle">Access to our dashboard</p>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-3 input_text">
                        <label className="form-control-label">
                          Email Address
                        </label>
                        <Controller
                          name="username"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control  ${
                                errors?.username ? "error-input" : ""
                              }`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />

                        <small className="error">
                          {errors?.username?.message}
                        </small>
                      </div>
                      <div className="input-block mb-3 input_text login-eye">
                        <label className="form-control-label">Password</label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div className="pass-group">
                              <input
                                type={eye ? "password" : "text"}
                                className={`form-control  ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                              />
                              <span
                                onClick={onEyeClick}
                                className={`fas toggle-password ${
                                  eye ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </div>
                          )}
                        />

                        {/* <small>{errors?.password?.message}</small> */}
                      </div>
                      <div className="input-block mb-3">
                        <div className="row">
                          {/* <div className="col-6">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="cb1"
                              />
                              <label
                                className="custom-control-label ms-1"
                                htmlFor="cb1"
                              >
                                Remember me
                              </label>
                            </div>
                          </div> */}
                          <div className="col-6 text-end">
                            <Link className="forgot-link" to="/forgot-password">
                              {" "}
                              Forgot Password ?{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-lg btn-block w-100 btn-primary w-100"
                        type="submit"
                      >
                        {loading ? <Spin /> : "Login"}
                      </button>
                      {/* {error && <h1 style={{color:'red'}}>{error}</h1>} */}
                    </form>
                    <div className="login-or">
                      <span className="or-line" />
                      <span className="span-or">or</span>
                    </div>
                    <div className="text-center dont-have">
                      Don't have an account yet?{" "}
                      <Link to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
