import React from "react";
import PropTypes from 'prop-types';

export default function CustomInput({
  id,
  name,
  type,
  value,
  onChange,
  placeholder,
  label,
  error,
  errorMsg,
  apiError,
  autoComplete,
  disabled,
  required
}) {
  return (
    <>
      {label && (
        <>
          <label>{label} &nbsp;
          {required ? <span className="text-danger">*</span> : ""}
          </label>
        </>
      )}
      <input
        className={`form-control  ${error ? "error-input" : ""}`}
        id={id}
        name={name}
        type={type}
        value={type != "file" ? value : ""}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      {apiError && <p className="text-danger">{apiError}</p>}
      {errorMsg && <p className="text-danger">{errorMsg}</p>}
    </>
  );
}

CustomInput.propTypes = {
  id : PropTypes.string.isRequired,
  name : PropTypes.string,
  type :  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value : PropTypes.string,
  onChange : PropTypes.func,
  placeholder : PropTypes.string.isRequired,
  label : PropTypes.string.isRequired,
  error : PropTypes.string,
  errorMsg : PropTypes.object,
  apiError : PropTypes.object,
  autoComplete : PropTypes.string,
  disabled : PropTypes.bool,
  required : PropTypes.bool,
};
