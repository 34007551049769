import { Spin } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import CustomInput from "../_components/inputField/custominput";
import PropTypes from "prop-types";
import Select from "react-select";
import CreateModal from "../_components/modalbox/createmodal";

const UserAddEditModal = ({
  id,
  show,
  title,
  handleAddSubmit,
  handleSubmit,
  control,
  handleChange,
  apiError,
  errors,
  editData,
  addData,
  managerData,
  handleRolesChange,
  rolesData,
  teamMemberCreateLoading,
  selectRefs,
}) => {
  const addEditUser = (
    <CreateModal
      id={id}
      show={show}
      title={title}
      modalbody={
        <>
          <form onSubmit={handleSubmit(handleAddSubmit)}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomInput
                          id={
                            id === "edit_user"
                              ? "edit-first_name"
                              : "first_name"
                          }
                          type="text"
                          value={value}
                          onChange={(e) => {
                            handleChange(e);
                            onChange(e);
                          }}
                          placeholder="First Name"
                          label="First Name"
                          errorMsg={errors?.first_name?.message}
                          error={errors?.first_name}
                          apiError={apiError?.first_name}
                          autoComplete="false"
                          required={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomInput
                          id={
                            id === "edit_user" ? "edit-last_name" : "last_name"
                          }
                          type="text"
                          value={value}
                          onChange={(e) => {
                            handleChange(e);
                            onChange(e);
                          }}
                          placeholder="Last Name"
                          label="Last Name"
                          errorMsg={errors?.last_name?.message}
                          error={errors?.last_name}
                          apiError={apiError?.last_name}
                          autoComplete="false"
                          required={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomInput
                          id={id === "edit_user" ? "edit-email" : "email"}
                          type="text"
                          value={value}
                          onChange={(e) => {
                            handleChange(e);
                            onChange(e);
                          }}
                          placeholder="Email"
                          label="Email"
                          errorMsg={errors?.email?.message}
                          error={errors?.email}
                          apiError={apiError?.email}
                          autoComplete="false"
                          required={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomInput
                          id={id === "edit_user" ? "edit-phone" : "phone"}
                          type="text"
                          value={value && value.length >= 3 ? value : "+91"} // Ensure +91 is there on initial load
                          onChange={(e) => {
                            let inputValue = e.target.value;

                            // Remove any non-numeric characters except for the initial +91
                            inputValue = inputValue.replace(/[^0-9]/g, "");

                            // Always prepend +91 to the input, but allow backspacing after it
                            if (inputValue.length >= 2) {
                              inputValue = "+91" + inputValue.substring(2);
                            } else {
                              inputValue = "+91"; // Keep +91 if backspacing below length 3
                            }

                            // Pass the value back to the controller and handle change events
                            handleChange({
                              ...e,
                              target: { ...e.target, value: inputValue },
                            });
                            onChange(inputValue);
                          }}
                          placeholder="Mobile"
                          label="Mobile"
                          errorMsg={errors?.phone?.message}
                          error={errors?.phone}
                          apiError={apiError?.phone?.message}
                          autoComplete="false"
                          required={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label>Manager</label>
                    <Select
                      value={
                        id === "edit_user"
                          ? editData?.manager
                          : addData?.manager
                      }
                      options={
                        managerData
                          ? managerData?.map((option) => ({
                              value: option.id,
                              label: `${option?.info.first_name} 
                                  ${option?.info.last_name}`,
                            }))
                          : []
                      }
                      onChange={(selectedOption) => {
                        id === "edit_user"
                          ? handleRolesChange(selectedOption, "edit-manager")
                          : handleRolesChange(selectedOption, "manager");
                      }}
                    />
                    {apiError?.manager && (
                      <p className="text-danger">{apiError?.manager}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label>
                      Role Permission <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name={"role"}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          ref={(el) =>
                            id === "edit_user"
                              ? (selectRefs.current["edit-role"] = el)
                              : (selectRefs.current["role"] = el)
                          }
                          options={rolesData?.map((option) => ({
                            value: option.value,
                            label: option.name,
                          }))}
                          onChange={(selectedOption) => {
                            id === "edit_user"
                              ? handleRolesChange(selectedOption, "edit-role")
                              : handleRolesChange(
                                  selectedOption,
                                  "role",
                                  field.onChange(selectedOption)
                                );
                          }}
                        />
                      )}
                    />

                    {errors?.role?.message && (
                      <p className="text-danger">{errors?.role?.message}</p>
                    )}
                  </div>
                  {apiError?.role && (
                    <p className="text-danger">{apiError?.role}</p>
                  )}
                  {apiError?.user && (
                    <p className="text-danger">{apiError?.user}</p>
                  )}
                </div>
              </div>
              {apiError?.length && 
                <p className="text-danger">{apiError[0]}</p>
              }
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {teamMemberCreateLoading ? <Spin /> : "Submit"}
              </button>
            </div>
          </form>
        </>
      }
    />
  );

  return <>{addEditUser}</>;
};

UserAddEditModal.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleAddSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  apiError: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editData: PropTypes.object,
  addData: PropTypes.object,
  managerData: PropTypes.array,
  handleRolesChange: PropTypes.func.isRequired,
  rolesData: PropTypes.array,
  teamMemberCreateLoading: PropTypes.bool,
  selectRefs: PropTypes.object.isRequired,
};

export default UserAddEditModal;
