import axios from "axios";
import store from "./store";
import { setError } from "./actions/commonApiAction";
import { signOut } from "./actions/UserAction";
// import { signOut } from "./actions/authActions"; // Assuming signOut is an action in authActions

export let mode = "staging";
// export let mode = "sandbox";

let baseValues = {
  baseProtocol: {
    // prod: "https://",
    staging: "https://",
    sandbox: "https://",
  },

  baseHost: {
    staging: "api.tideiq.com",
    // prod: "api.tideiq.com",
    sandbox: "beta.api.tideiq.com",
  },
};

let baseProtocol = baseValues.baseProtocol[mode];
let baseHost = baseValues.baseHost[mode];
export const baseURL = baseProtocol + baseHost;

const HTTP = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Counter to track consecutive 401 errors
let consecutive401Errors = 0;

HTTP.interceptors.response.use(
  (response) => {
    // Reset counter on successful response
    consecutive401Errors = 0;
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check for 401 Unauthorized errors and handle token refresh logic
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      consecutive401Errors += 1;

      if (consecutive401Errors > 7) {
        // Call the signOut action from the Redux store
        store.dispatch(signOut());
        return Promise.reject(error); // Stop further requests after signOut
      }

      try {
        const refresh = localStorage.getItem("refresh");
        if (refresh) {
          const refreshToken = JSON.parse(refresh);
          if (refreshToken) {
            const { data } = await HTTP.post("/users/token/refresh/", {
              refresh: refreshToken,
            });
            const { access } = data;
            localStorage.setItem("userInfo", JSON.stringify(access));

            originalRequest.headers.Authorization = `Bearer ${access}`;
            return HTTP(originalRequest);
          }
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    }

    // Check for 403 Forbidden errors and handle specific error codes
    if (error.response.status === 403) {
      const errorCode = error.response.data?.error_code;
      if (errorCode === "MAX_LIMIT_REACHED" || errorCode === "NO_ACTIVE_PLAN") {
        let message =
          errorCode === "MAX_LIMIT_REACHED"
            ? "Maximum limit reached. Please upgrade your plan."
            : "No active plan. Please upgrade.";
        store.dispatch(setError(message));
      }
    }

    // Handle 500 Internal Server Error
    if (error.response.status === 500) {
      let message = "An unexpected error occurred. Please try again later.";
      store.dispatch(setError(message));
    }

    return Promise.reject(error); // Reject the promise to allow further error handling
  }
);

export { HTTP };