import {
  VENDOR_ADD_REQUEST,
  VENDOR_ADD_SUCCESS,
  VENDOR_ADD_FAIL,
  GET_VENDORLIST_FAIL,
  GET_VENDORLIST_REQUSET,
  GET_VENDORLIST_SUCCESS,
  VENDORLISTSET_PAGE,
  VENDOR_EDIT_DETAIL_REQUEST,
  VENDOR_EDIT_REQUEST,
  VENDOR_EDIT_DETAIL_FAIL,
  VENDOR_EDIT_DETAIL_SUCCESS,
  VENDOR_EDIT_SUCCESS,
  VENDOR_EDIT_FAIL,
  VENDOR_SEARCH_REQUEST,
  VENDOR_SEARCH_SUCCESS,
  VENDOR_SEARCH_FAIL,
  VENDOR_EXISTING_ADD_REQUEST,
  VENDOR_EXISTING_ADD_SUCCESS,
  VENDOR_EXISTING_ADD_FAIL,
} from "../constants/VendorConstant";
import { HTTP } from "../api";

export const addVendor = (payload) => async (dispatch, getState) => {
  dispatch({ type: VENDOR_ADD_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("vendor/vendor/", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: VENDOR_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VENDOR_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const apInvoiceAddVendor = (payload) => async (dispatch, getState) => {
  dispatch({ type: VENDOR_ADD_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("vendor/vendor/", payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: VENDOR_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VENDOR_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const setPage = (page, perPage) => ({
  type: VENDORLISTSET_PAGE,
  payload: page,
  perPage,
});

export const getVendorList = (page = 1, perPage = 10) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_VENDORLIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/vendor/vendor/?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_VENDORLIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: GET_VENDORLIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const vendorEditDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: VENDOR_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/vendor/vendor/${id}`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: VENDOR_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VENDOR_EDIT_DETAIL_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};

export const updateVendorDetail = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: VENDOR_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.put(`/vendor/vendor/${id}/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: VENDOR_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VENDOR_EDIT_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};


export const getVendorSearchList = (searchValue, page, perPage) => async (
  dispatch,
  getState
) => {
  dispatch({ type: VENDOR_SEARCH_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  
  try {
    // Initialize the query parameters
    let queryParams = `/vendor/vendor/?search=${searchValue}`;

    // Conditionally add `page` and `perPage` to the query if they exist
    if (page) {
      queryParams += `&page=${page}`;
    }
    if (perPage) {
      queryParams += `&per_page=${perPage}`;
    }

    const { data } = await HTTP.get(queryParams, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });

    dispatch({
      type: VENDOR_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_SEARCH_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};


export const createExistingVendor = (payload, vendorId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: VENDOR_EXISTING_ADD_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      `/invoice/ap-invoice/${vendorId}/vendor/${payload}/connect/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: VENDOR_EXISTING_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VENDOR_EXISTING_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
