import React from "react";
import PropTypes from "prop-types";
import { teamMemberCreateApi } from "../redux/actions/UserAction";
import UserAddEditModal from "./userAddEdit";

const UserManagementAddModal = ({
  show,
  handleSubmit,
  handleChange,
  handleRolesChange,
  rolesData,
  managerData,
  addData,
  teamMemberCreateLoading,
  apiError,
  errors,
  // Controller,
  control,
  selectRefs,
  dispatch,
}) => {


  
  const handleAddSubmit = (data) => {
    const postData = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone: data?.phone,
      manager: addData?.manager?.value,
      role: addData?.role?.value,
    };
    dispatch(teamMemberCreateApi(postData));
  };

  const addUser=(
    <UserAddEditModal
    id={"add_user"}
    show={show}
    title={"Add User"}
    handleAddSubmit={handleAddSubmit}
    handleSubmit={handleSubmit}
    control={control}
    handleChange={handleChange}
    apiError={apiError}
    errors={errors}
    addData={addData}
    managerData={managerData}
    handleRolesChange={handleRolesChange}
    rolesData={rolesData}
    teamMemberCreateLoading={teamMemberCreateLoading}
    selectRefs={selectRefs}
    />
  )
  return (
    <>{addUser}</>
  );
};

UserManagementAddModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rolesData: PropTypes.array,
  addData: PropTypes.object.isRequired,
  teamMemberCreateLoading: PropTypes.string,
  apiError: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  Controller: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleRolesChange: PropTypes.func.isRequired,
  selectRefs: PropTypes.object.isRequired,
  managerData: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
};

export default UserManagementAddModal;
