/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import { message, Spin, Table } from "antd";
import DatePicker from "react-datepicker";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import { useDispatch } from "react-redux";
import {
  getInvoiceEditDetail,
  getInvoiceList,
  sendArApproval,
  setPage,
} from "../../redux/actions/ARmoduleAction";
import { useSelector } from "react-redux";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
  formatDateYYMMDD,
  invoiceDetails,
} from "./constant";
import DeleteModal from "../../_components/modalbox/deletemodal";
import SubmitToErpModal from "./submitToErpModal";
import { INVOICE_SUBMIT_ERP_RESET } from "../../redux/constants/ARmoduleConstant";

const InvoiceList = () => {
  const [menu, setMenu] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [filterData, setFilterData] = useState({
    invoice_number: "",
    invoice_date: "",
    nature_of_supply: "",
  });
  const [editData, setEditData] = useState({});
  const [show, setShow] = useState(false);

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const [submitToErpError, setSubmitToErpError] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const invoiceList = useSelector((state) => state?.invoiceList);
  const { loading: invoiceListLoading, arInvoice, page, perPage } = invoiceList;

  const editInvoiceDetail = useSelector((state) => state.editInvoiceDetail);
  const { invoiceEditDetail, loading: editInvoiceLoading } = editInvoiceDetail;

  const arModuleSubmitErp = useSelector((state) => state?.arModuleSubmitErp);
  const { loading, success, error } = arModuleSubmitErp;

  const userData = useSelector((state) => state.userSignin);
  const { permissions } = userData;

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDateFilterChange = (selectedDate, id) => {
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  const onModalClose = () => {
    $("#submit_to_erp").on("hide.bs.modal", () => {
      setEditData({});
      setSubmitToErpError("");
      dispatch({ type: INVOICE_SUBMIT_ERP_RESET });
    });
  };

  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    dispatch(
      getInvoiceList(
        page,
        perPage,
        filterData?.invoice_number,
        filterData?.invoice_date
          ? formatDateYYMMDD(filterData?.invoice_date)
          : "",
        filterData?.nature_of_supply
      )
    );
  }, [page, perPage, filterData]);

  useEffect(() => {
    if (error) {
      setSubmitToErpError(error);
    }
  }, [error]);

  const handleEditClick = (record) => {
    setEditData(record);
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      sorter: (a, b) => a.invoice_number.length - b.invoice_number.length,
    },
    {
      title: "Date",
      dataIndex: "invoice_date",
      sorter: (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      sorter: (a, b) => a?.customer?.length - b?.customer?.length,
    },
    {
      title: "Description/Period",
      dataIndex: "description",
      sorter: (a, b) => a?.description?.length - b?.description?.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      sorter: (a, b) => a.tax - b.tax,
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          {permissions?.arModule?.addEditTransactions && (
            <>
              <Link
                to={`/ar-module/arModule-edit-invoice/${record?.id}`}
                className="btn btn-primary me-2"
              >
                <i className="far fa-edit" />
              </Link>
              <Link
                className="btn btn-greys"
                data-bs-toggle="modal"
                to="#submit_to_erp"
                role="button"
                onClick={() => handleEditClick(record)}
              >
                <i className="far fa-send" />
              </Link>
            </>
          )}
        </>
      ),
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(sendArApproval(editData?.id));
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  useEffect(() => {
    if (success) {
      handleModalClose("#submit_to_erp");
      closeModal();
      setEditData({});
      setSubmitToErpError("");
      dispatch({ type: INVOICE_SUBMIT_ERP_RESET });
      messageApi.open({
        type: "success",
        content: "Submitted to Zoho",
        duration: 5,
      });
    }
  }, [success]);

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      dispatch(getInvoiceEditDetail(record?.id));
    }
    const keys = expanded ? [record?.id] : [];
    setExpandedRowKeys(keys);
    // const keys = expanded
    //   ? [...expandedRowKeys, record.id]
    //   : expandedRowKeys.filter((key) => key !== record.id);
    // setExpandedRowKeys(keys);
  };

  const expandedRowRender = (record) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          <table>
            <thead>
              {invoiceEditDetail?.invoice_approval?.rejected_reason && (
                <tr>
                  <th>
                    {" "}
                    Rejected Reason :{" "}
                    {invoiceEditDetail?.invoice_approval?.rejected_reason}
                  </th>
                </tr>
              )}
              <tr>
                <th>S.No</th>
                <th>Posting Ledger</th>
                <th>Description</th>
                <th>HSN/SAC</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>GST Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            {editInvoiceLoading ? (
              <Spin />
            ) : (
              <tbody>
                {invoiceEditDetail?.items?.map((ele, i) => {
                  return (
                    <tr key={ele?.id}>
                      <td>{i + 1}</td>
                      <td>{ele?.ledger?.title}</td>
                      <td className="tdDesc">{ele?.description}</td>
                      <td>{ele?.product_code}</td>
                      <td>{ele?.quantity}</td>
                      <td>{ele?.rate}</td>
                      <td>{ele?.gst_rate}</td>
                      <td>{ele?.amount}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>{invoiceEditDetail?.total_amount}</td>
                </tr>
                {invoiceEditDetail?.tax_items?.map((tax) => (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{tax?.tax_type}</td>
                    <td>{tax?.tax_amount}</td>
                  </tr>
                ))}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total Tax</td>
                  <td>{invoiceEditDetail?.total_tax}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>TDS</td>
                  <td>{invoiceEditDetail?.total_tds_amount}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Amount Payable</td>
                  <td>{invoiceEditDetail?.amount_payable}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        {contextHolder}
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>AR module</a>
                    </li>
                    <li className="breadcrumb-item active">Invoice List</li>
                  </ul>
                </div>
                {permissions?.arModule?.addEditTransactions && (
                  <div className="list-btn">
                    <ul className="filter-list filterbutton">
                      <li>
                        <Link
                          to={`/ar-module/arModule-add-invoice`}
                          className="btn btn-primary"
                        >
                          <i
                            className="fa fa-plus-circle me-2"
                            aria-hidden="true"
                          />
                          Create Invoice
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <ul className="filter-list">
                <li>
                  <input
                    id="invoice_number"
                    type="text"
                    placeholder="Invoice Number"
                    value={filterData?.invoice_number}
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </li>

                <li>
                  <div
                    style={containerStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DatePicker
                      className="datetimepicker form-control"
                      id="invoice_date"
                      selected={filterData?.invoice_date}
                      placeholderText="Invoice Date"
                      onChange={(selectedDate) =>
                        handleDateFilterChange(selectedDate, "invoice_date")
                      }
                    />
                    {filterData?.invoice_date && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() =>
                          handleDateFilterChange(null, "invoice_date")
                        }
                        aria-label="Clear Date"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </div>
                </li>
                <li>
                  <input
                    id="nature_of_supply"
                    type="text"
                    placeholder="Nature of Supply"
                    value={filterData?.nature_of_supply}
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-55">
                      <Table
                        className="table armoduleList"
                        loading={invoiceListLoading}
                        pagination={{
                          total: arInvoice?.count,
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        expandable={{
                          expandedRowRender,
                          expandedRowKeys,
                          onExpand: handleExpand,
                        }}
                        columns={columns}
                        dataSource={arInvoice?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubmitToErpModal
          show={show}
          submitToErpError={submitToErpError}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </>
  );
};
export default InvoiceList;
