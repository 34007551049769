/* eslint-disable */
import { message, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CreateModal from "../../_components/modalbox/createmodal";
import CustomInput from "../../_components/inputField/custominput";
import { useDispatch } from "react-redux";
import { updateOrganizationApi } from "../../redux/actions/OrganizationAction";
import { useSelector } from "react-redux";
import { ORGANIZATION_RESET } from "../../redux/constants/OrganizationConstant";
import { getCompanyList } from "../../redux/actions/commonApiAction";

const schema = yup.object({
  name: yup
    .string()
    .required("Organizaion Name is required")
    .trim(),
  gstin_number: yup
    .string()
    .required("GST is required")
    .trim(),
  pan_number: yup
    .string()
    .required("PAN is required")
    .trim(),
});

const OrganizationModal = ({
  id,
  title,
  setOrganizationData,
  organizationData,
  company,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const organizationUpdation = useSelector(
    (state) => state?.organizationUpdation
  );
  const { error, loading, success } = organizationUpdation;
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [editError, setEditError] = useState({});

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: organizationData,
  });

  const onModalClose =()=>{
    $("#edit_organization").on("hide.bs.modal", () => {
        reset();
        dispatch({ type: ORGANIZATION_RESET });
      });
  }

  useEffect(()=>{
    onModalClose()
  },[])

  useEffect(() => {
    if (error) {
      setEditError(error);
    }
  }, [error]);

  useEffect(() => {
    if (company) {
      setOrganizationData(company);
    }
  }, [company]);

  useEffect(() => {
    if (success) {
      dispatch({ type: ORGANIZATION_RESET });
      handleModalClose("#edit_organization");
      messageApi.open({
        type: "success",
        content: "Organization Updated successfully",
        duration: 5,
      });
      dispatch(getCompanyList());
    }
  }, [success]);

  useEffect(() => {
    if (organizationData) {
      Object.keys(organizationData).forEach((field) => {
        setValue(field, organizationData[field]);
      });
    }
  }, [organizationData, setValue]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const OrganizationSubmit = (data) => {
    const postData = {
      name: data?.name,
      gstin_number: data?.gstin_number,
      pan_number: data?.pan_number,
    };
    dispatch(updateOrganizationApi(postData));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setOrganizationData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const organizationEdit = (
    <CreateModal
      id={id}
      modalRef={modalRef}
      //   show={show}
      title={title}
      modalbody={
        <>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={handleSubmit(OrganizationSubmit)}>
                <div className="col-md-12">
                  <div className="form-group-item border-0 mb-0">
                    <div className="row align-item-center">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <Controller
                            name="name"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomInput
                                  id={"name"}
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onChange(e);
                                  }}
                                  placeholder="Name"
                                  label="Name"
                                  errorMsg={errors?.name?.message}
                                  error={errors?.name}
                                  apiError={editError?.name}
                                  autoComplete="false"
                                  required={true}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <Controller
                            name="gstin_number"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomInput
                                  id={"gstin_number"}
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onChange(e);
                                  }}
                                  placeholder="GST"
                                  label="GST"
                                  errorMsg={errors?.gstin_number?.message}
                                  error={errors?.gstin_number}
                                  apiError={editError?.gstin_number}
                                  autoComplete="false"
                                  required={true}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <Controller
                            name="pan_number"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomInput
                                  id={"pan_number"}
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onChange(e);
                                  }}
                                  placeholder="PAN"
                                  label="PAN"
                                  errorMsg={errors?.pan_number?.message}
                                  error={errors?.pan_number}
                                  apiError={editError?.pan_number}
                                  autoComplete="false"
                                  required={true}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="reset"
                    className="btn btn-primary cancel me-2"
                    onClick={() => {
                      handleModalClose("#edit_organization");
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? <Spin /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      }
    />
  );

  return (
    <>
      {contextHolder}
      {organizationEdit}
    </>
  );
};

OrganizationModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setOrganizationData: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  organizationData: PropTypes.object.isRequired,
};

export default OrganizationModal;
