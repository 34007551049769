import { HTTP } from "../api";
import {
  BANKLISTSET_PAGE,
  BANK_ADD_FAIL,
  BANK_ADD_REQUEST,
  BANK_ADD_SUCCESS,
  BANK_EDIT_DETAIL_FAIL,
  BANK_EDIT_DETAIL_REQUEST,
  BANK_EDIT_DETAIL_SUCCESS,
  BANK_EDIT_FAIL,
  BANK_EDIT_REQUEST,
  BANK_EDIT_SUCCESS,
  BANK_GET_FAIL,
  BANK_GET_LIST_FAIL,
  BANK_GET_LIST_REQUEST,
  BANK_GET_LIST_SUCCESS,
  BANK_GET_REQUEST,
  BANK_GET_SUCCESS,
  BANK_STATEMENT_FAIL,
  BANK_STATEMENT_VIEW_REQUEST,
  BANK_STATEMENT_VIEW_SUCCESS,
  BANK_UPLOAD_FAIL,
  BANK_UPLOAD_REQUEST,
  BANK_UPLOAD_SUCCESS,
  TRANSACTION_CREATE_FAIL,
  TRANSACTION_CREATE_REQUEST,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_DETAIL_FAIL,
  TRANSACTION_DETAIL_REQUEST,
  TRANSACTION_DETAIL_SUCCESS,
  TRANSACTION_SUBMIT_TO_ZOHO_FAIL,
  TRANSACTION_SUBMIT_TO_ZOHO_REQUEST,
  TRANSACTION_SUBMIT_TO_ZOHO_SUCCESS,
  TRANSACTION_UPDATE_FAIL,
  TRANSACTION_UPDATE_REQUEST,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_VIEW_FAIL,
  TRANSACTION_VIEW_REQUEST,
  TRANSACTION_VIEW_SUCCESS,
} from "../constants/BankConstant";

export const bankCreation = (payload) => async (dispatch, getState) => {
  dispatch({ type: BANK_ADD_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/bank/", payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: BANK_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BANK_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const setPage = (page, perPage) => ({
  type: BANKLISTSET_PAGE,
  payload: page,
  perPage,
});

export const getBankListApi = (page = 1, perPage = 10, accountType) => async (
  dispatch,
  getState
) => {
  dispatch({ type: BANK_GET_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/bank/?page=${page}&per_page=${perPage}&account_type=${accountType}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: BANK_GET_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: BANK_GET_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const bankEditDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: BANK_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/bank/${id}`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: BANK_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BANK_EDIT_DETAIL_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};

export const updateBankDetail = (payload, id) => async (dispatch, getState) => {
  dispatch({ type: BANK_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.put(`/bank/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: BANK_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BANK_EDIT_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};

export const getBankDropdownList = () => async (dispatch, getState) => {
  dispatch({ type: BANK_GET_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/bank/bank-all/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: BANK_GET_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BANK_GET_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const uploadBankInfo = (payload) => async (dispatch, getState) => {
  dispatch({ type: BANK_UPLOAD_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      "/bank/upload-bank-statement-v2/",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: BANK_UPLOAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BANK_UPLOAD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const transactionViewApi = (
  page,
  perPage,
  id,
  isUncategorized,
  party_name,
  date,
  account_name
) => async (dispatch, getState) => {
  dispatch({ type: TRANSACTION_VIEW_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/bank/transactions/?account=${id}&page=${page}&per_page=${perPage}&party_name__isnull=${isUncategorized}&party_name=${party_name}&date=${date}&account__account_name=${account_name}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: TRANSACTION_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TRANSACTION_VIEW_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};

export const bankStatementViewApi = (
  page,
  perPage,
  id,
  bank_name,
  account_name,
  uploaded_date,
  status
) => async (dispatch, getState) => {
  dispatch({ type: BANK_STATEMENT_VIEW_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/bank/statement/?account=${id}&page=${page}&per_page=${perPage}&account__account_name=${bank_name}&account__account_name=${account_name}&uploaded_date=${uploaded_date}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: BANK_STATEMENT_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BANK_STATEMENT_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};

export const transactionCreationApi = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: TRANSACTION_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/bank/transactions/", payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TRANSACTION_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TRANSACTION_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const transactionUpdationApi = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: TRANSACTION_UPDATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(`/bank/transactions/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TRANSACTION_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TRANSACTION_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const transactionDetailApi = (id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: TRANSACTION_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/bank/transactions/${id}/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TRANSACTION_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TRANSACTION_DETAIL_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const TransactionSubmitToZohoApi = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: TRANSACTION_SUBMIT_TO_ZOHO_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(`/bank/submit-to-zoho/`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: TRANSACTION_SUBMIT_TO_ZOHO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TRANSACTION_SUBMIT_TO_ZOHO_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
