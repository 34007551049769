/* eslint-disable */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { message, Spin, Select } from "antd";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Controller, useForm } from "react-hook-form";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import Select from "react-select";
import { formatDateYYMMDD } from "./constant";
import CustomInput from "../../_components/inputField/custominput";
import { getLedgerModuleList } from "../../redux/actions/LedgerModuleAction";
import { AP_INVOICE_CREATE_RESET } from "../../redux/constants/APmoduleConstant";
import {
  CreateAPinvoice,
  getTdsRateList,
  tdsCalculationApi,
} from "../../redux/actions/APmoduleAction";
import { postLedgerModuleList } from "../../redux/actions/LedgerModuleAction";
import Header from "../../layouts/Header";
import {
  getCompanyList,
  getTdsSectionList,
} from "../../redux/actions/commonApiAction";
import Sidebar from "../../layouts/Sidebar";
import { getVendorSearchList } from "../../redux/actions/VendorAction";
import CustomSelect from "../../customers/customSelect";
import AddLedgerModal from "../../customers/addLedgerModal";
import { POST_LEDGERLIST_RESET } from "../../redux/constants/ledgerListModuleConstant";
import useUpdateFooterData from "../../customers/updateFooterData";
import useCalculateTotals from "../../helpers/calculation";
import InvoiceErrorDisplay from "../../components/CustomError";

const schema = yup.object({
  vendor_id: yup
    .number()
    .required("Vendor ID is required")
    .typeError("Vendor ID must be a number"),
  invoice_number: yup
    .string()
    .required("Invoice number is required")
    .trim(),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
});

const addInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const apModuleInvoiceADD = useSelector((state) => state.createAPinvoice);
  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const companyList = useSelector((state) => state.companyList);
  const { company } = companyList;

  const tdsSectionList = useSelector((state) => state?.tdsSectionList);
  const { loading: tdsSectionListLoading, tdsSectionData } = tdsSectionList;

  const { success, loading, error } = apModuleInvoiceADD;

  const tdsCalculation = useSelector((state) => state.tdsCalculation);
  const {
    tdsAmount,
    loading: tdsCalculationLoading,
    error: tdsCalculationError,
  } = tdsCalculation;

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
  } = postLedgerList;

  const selectRef = useRef(null);
  const vendorSelectRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {
      setIsModalVisible(true);
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  useEffect(() => {
    if (postLedgerSuccess) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      setLedgerPage({ page: 1, perPage: 10 });
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess]);

  const [menu, setMenu] = useState();
  const [addData, setAddData] = useState({ vendor_id: null });
  const [editError, setEditError] = useState({});
  const [ledgerListData, setLedgerData] = useState([]);
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [tdsData, setTdsData] = useState([]);
  const [tdsPage, setTdsPage] = useState({ page: 1, perPage: 10 });
  const [itemId, setItemId] = useState("");
  const [file, setFile] = useState([]);
  const [page, setPage] = useState({ page: 1, perPage: 10 });
  const [showErrors, setShowErrors] = useState(true);

  const [data, setData] = useState([
    {
      posting_ledger: null,
      description: "",
      quantity: "",
      rate: "",
      gst_rate: "",
      gst_amount: "",
      amount: "",
      total: "",
      taxes: [],
      tds_ledger: null,
      tds_amount: "",
    },
  ]);
  const [footerData, setFooterData] = useState({});
  const [editable, setEditable] = useState({});
  const [calculationData, setCalculationData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [vendorList, setVendorList] = useState([]);
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemsError, setItemsError] = useState([]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
    fileInputRef.current.value = "";
    setFile({});
  };

  const [isChanged, setIsChanged] = useState(false);

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const totals = useCalculateTotals(data);

  useUpdateFooterData(footerData, setFooterData, totals, [], company, addData);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleLedgerSelectChange = (id, selectedOption) => {
    setAddLedgerData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCellChange = (rowId, field, value, index, row) => {
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);

    if (
      (field === "quantity" || field === "rate" || field === "gst_rate") &&
      row?.tds_ledger &&
      row?.description
    ) {
      setItemId(index);
      const item = {
        posting_ledger: row?.posting_ledger,
        tds_ledger: row?.tds_ledger,
        description: row?.description,
        product_code: row?.product_code,
        gst_rate: row?.gst_rate ? parseFloat(row?.gst_rate)?.toFixed(2) : "",
        quantity: row?.quantity ? parseFloat(row?.quantity)?.toFixed(2) : "",
        amount: row?.gst_amount ? parseFloat(row?.gst_amount) : "",
        discount: row?.discount,
        rate: row?.rate ? parseFloat(row?.rate)?.toFixed(2) : "",
        total: row?.total ? parseFloat(row?.total) : "",
      };
      const postData = {
        item: item,
        tds_section_id: row?.tds_ledger,
      };
      dispatch(tdsCalculationApi(postData));
    }
    // if (
    //   updatedData[index] &&
    //   updatedData[index]["rate"] &&
    //   updatedData[index]["quantity"]
    // ) {
    //   const updatedError = [...itemsError];
    //   delete updatedError[index]["total"];
    //   delete updatedError[index][field];
    //   setItemsError(updatedError);
    // }
    // const updatedError = [...itemsError];
    // if (updatedError[index]) {
    //   delete updatedError[index][field];
    //   setItemsError(updatedError);
    // }
    setIsChanged(true);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: AP_INVOICE_CREATE_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!addData?.vendor_gstin && addData?.vendor_id) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [addData?.vendor_gstin, addData?.vendor_id]);

  const handleSelectCellChange = (selectedOption, id, rowKey, index, row) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;

    if (id === "tds_ledger") {
      const item = {
        posting_ledger: row?.posting_ledger,
        tds_ledger: row?.tds_ledger,
        description: row?.description,
        product_code: row?.product_code,
        quantity: row?.quantity,
        amount: row?.gst_amount ? parseFloat(row?.gst_amount) : "",
        discount: row?.discount,
        rate: row?.gst_rate,
        total: row?.total ? parseFloat(row?.total) : "",
      };
      setData(updatedData);
      setItemId(index);
      const data = {
        tds_section_id: selectedOption,
        item: item,
      };

      dispatch(tdsCalculationApi(data));
      setIsChanged(true);
    }

    setData(updatedData);

    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const discount = updatedData[calculationData?.indexId]["discount"] || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = (rate - discount) * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        posting_ledger: null,
        description: "",
        quantity: "",
        rate: "",
        amount: "",
        gst_rate: "",
        gst_amount: "",
        tds_ledger: null,
        tds_amount: "",
      },
    ]);
    setAddLedgerData({ title: "", group: null, description: "" });
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    const filterData = data?.filter((el, index) => index !== tabIndex);
    setIsChanged(true);
    setData(filterData);
  };

  const renderCell = (rowId, field, value, index, row) => {
    if (editable.rowId === rowId && editable.field === field) {
      if (field === "posting_ledger") {
        return (
          <>
            <CustomSelect
              id={"posting_ledger"}
              value={value}
              onChange={(selectedOption) => {
                handleSelectCellChange(
                  selectedOption,
                  "posting_ledger",
                  rowId,
                  index,
                  row
                );
              }}
              options={ledgerListData?.length ? ledgerListData : []}
              addLedgerData={addLedgerData}
              setAddLedgerData={setAddLedgerData}
              handleSubmit={handleSubmit}
              handleEditSubmit={handleAddSubmit}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              control={control}
              errors={errors}
              handleModalClose={handleModalClose}
              setApiError={setApiError}
              apiError={apiError}
              onPopupScroll={handleLedgerPopupScroll} // Add scroll handling
              loading={ledgerListLoading}
            />
          </>
        );
      } else if (field === "tds_ledger") {
        return (
          <Select
            value={value}
            options={
              tdsData.length
                ? tdsData?.map((option) => ({
                    value: option.id,
                    label: option.section_name,
                  }))
                : []
            }
            style={{ width: 300 }}
            disabled={!row?.description}
            onChange={(selectedOption) => {
              handleSelectCellChange(
                selectedOption,
                "tds_ledger",
                rowId,
                index,
                row
              );
            }}
            onPopupScroll={handleTdsPopupScroll}
            dropdownRender={(menu) => (
              <>
                {menu}
                {tdsSectionListLoading && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: 8,
                    }}
                  >
                    <Spin />
                  </div>
                )}
              </>
            )}
          />
        );
      } else if (field === "tds_amount") {
        return (
          <input
            type={"text"}
            readOnly={!row?.description}
            value={value}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index, row)
            }
          />
        );
      } else {
        return (
          <input
            style={{ cursor: "pointer" }}
            type={field === "description" ? "text" : "number"}
            disabled={
              field === "amount" ||
              field === "gst_amount" ||
              field === "total" ||
              (field === "gst_rate" && !addData?.vendor_gstin)
            }
            required
            value={value}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index, row)
            }
            onBlur={() => setEditable({})}
            autoFocus
          />
        );
      }
    }
    if (field === "posting_ledger") {
      return (
        <CustomSelect
          id={"posting_ledger"}
          value={value}
          onChange={(selectedOption) => {
            handleSelectCellChange(
              selectedOption,
              "posting_ledger",
              rowId,
              index,
              row
            );
          }}
          required
          options={ledgerListData?.length ? ledgerListData : []}
          addLedgerData={addLedgerData}
          setAddLedgerData={setAddLedgerData}
          handleSubmit={handleSubmit}
          handleEditSubmit={handleAddSubmit}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          control={control}
          errors={errors}
          handleModalClose={handleModalClose}
          setApiError={setApiError}
          apiError={apiError}
          onPopupScroll={handleLedgerPopupScroll} // Add scroll handling
          loading={ledgerListLoading}
        />
      );
    } else if (field === "tds_ledger") {
      return (
        <Select
          value={value}
          options={
            tdsData.length
              ? tdsData?.map((option) => ({
                  value: option.id,
                  label: option.section_name,
                }))
              : []
          }
          style={{ width: 300 }}
          disabled={!row?.description}
          onChange={(selectedOption) => {
            handleSelectCellChange(
              selectedOption,
              "tds_ledger",
              rowId,
              index,
              row
            );
          }}
          onPopupScroll={handleTdsPopupScroll}
          dropdownRender={(menu) => (
            <>
              {menu}
              {tdsSectionListLoading && (
                <div
                  style={{
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Spin />
                </div>
              )}
            </>
          )}
        />
      );
    } else if (field === "tds_amount") {
      return (
        <input
          type={"text"}
          readOnly={!row?.description}
          value={value}
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index, row)
          }
        />
      );
    } else {
      return (
        <input
          type={field === "description" ? "text" : "number"}
          value={value}
          disabled={
            field === "amount" ||
            field === "gst_amount" ||
            field === "total" ||
            (field === "gst_rate" && !addData?.vendor_gstin)
          }
          required
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index, row)
          }
          // onDoubleClick={() => handleDoubleClick(rowId, field)}
        />
      );
    }
  };

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleLedgerChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  useEffect(() => {
    dispatch(getTdsRateList());
  }, []);

  // useEffect(() => {
  //   setEditError(error);
  // }, [error]);

  useEffect(() => {
    if (error) {
      setShowErrors(error?.data.items ? true : false);
      setItemsError(error?.data.items ? error?.data.items : null);
    } else {
      setEditError(error?.error ? error?.error : {});
    }
  }, [error]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: addData,
  });

  useEffect(() => {
    const fieldOrder = [
      "vendor_id",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
    ];
    const firstErrorField = fieldOrder.find((field) => errors && errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "vendor_id") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = [
      "vendor_id",
      "vendor_code",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
      "detail",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => editError && editError[field]
    );
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "detail") {
        inputElement = selectRefs.current["tds_rate"];
      } else if (firstErrorField === "vendor_id") {
        inputElement = selectRefs.current["vendor_id"];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editError]);

  const handleAddSubmit = (datas) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      posting_ledger: ele?.posting_ledger,
      tds_ledger: ele?.tds_ledger,
      tds_amount: ele?.tds_amount
        ? parseFloat(ele?.tds_amount).toFixed(2)
        : null,
    }));
    if (isModalVisible) {
      const postData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    } else {
      const formData = new FormData();
      const jsonData = {
        vendor_id: datas?.vendor_id,
        // vendor_code: datas?.vendor_code,
        // vendor_gstin: datas?.vendor_gstin,
        invoice_number: datas?.invoice_number || null,
        invoice_date: datas?.invoice_date
          ? formatDateYYMMDD(datas?.invoice_date)
          : null,
        // expense_ledger: datas?.expense_ledger?.value,
        nature_of_supply: datas?.nature_of_supply || "",
        items: ledger,
      };
      formData.append("data", JSON.stringify(jsonData));
      file.forEach((f) => formData.append("invoice_file", f));
      dispatch(CreateAPinvoice(formData));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: AP_INVOICE_CREATE_RESET });
      setItemId("");
      setData([
        {
          posting_ledger: null,
          description: "",
          quantity: "",
          rate: "",
          gst_rate: "",
          gst_amount: "",
          amount: "",
          total: "",
          taxes: [],
          tds_ledger: null,
          tds_amount: "",
        },
      ]);
      setAddData({
        vendor_id: null,
        vendor_gstin: "",
        invoice_number: "",
        nature_of_supply: "",
        invoice_date: null,
      });
      fileInputRef.current.value = "";
      setFile([]);
      setItemsError([
        {
          ledger: "",
          description: "",
          quantity: "",
          rate: "",
          amount: "",
          gst_rate: "",
          gst_amount: "",
          total: "",
          tax_rate: "",
          product_code: "",
        },
      ]);
      messageApi.open({
        type: "success",
        content: "Invoice Created Successfully",
        duration: 5,
      });
      history.push("/apmodule");
    }
  }, [success]);

  useEffect(() => {
    if (tdsAmount) {
      const updatedData = [...data];
      if (updatedData[itemId]) {
        updatedData[itemId]["tds_amount"] = tdsAmount?.tds_amount
          ? parseFloat(tdsAmount?.tds_amount)?.toFixed(2)
          : 0;
        setData(updatedData);
      }
    }
  }, [itemId, tdsAmount]);

  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  useEffect(() => {
    if ((page?.page > 1 && vendorSearchList?.next) || page?.page === 1) {
      dispatch(getVendorSearchList("", page?.page, page?.perPage));
    }
  }, [page?.page, page?.perPage]);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const newVendors = vendorSearchList.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(vendorList.map((vendor) => vendor.id)); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...vendorList, ...uniqueNewVendors];

      setVendorList(combinedVendorList); // Update state with the combined list
    }
  }, [vendorSearchList]);

  useEffect(() => {
    if (addData?.vendor_id) {
      const filteredVendor = vendorList?.find(
        (el) => el?.id === addData?.vendor_id
      );
      setAddData((prevState) => ({
        ...prevState,
        vendor_code: filteredVendor?.vendor_code
          ? filteredVendor?.vendor_code
          : "",
        vendor_gstin: filteredVendor?.gstin_number
          ? filteredVendor?.gstin_number
          : "",
      }));
    }
  }, [vendorSearchList, addData?.vendor_id]);

  useEffect(() => {
    if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1) {
      dispatch(getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage));
    }
  }, [dispatch, ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    if (expenseList?.results) {
      const newVendors = expenseList?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        ledgerListData.map((vendor) => vendor.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
      setLedgerData(combinedVendorList); // Update state with the combined list
    }
  }, [expenseList]);

  useEffect(() => {
    if ((tdsPage?.page > 1 && tdsSectionData?.next) || tdsPage?.page === 1) {
      dispatch(getTdsSectionList(tdsPage?.page, tdsPage?.perPage));
    }
  }, [tdsPage?.page, tdsPage?.perPage]);

  useEffect(() => {
    if (tdsSectionData?.results) {
      const newVendors = tdsSectionData?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(tdsData.map((vendor) => vendor.id)); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...tdsData, ...uniqueNewVendors];
      setTdsData(combinedVendorList); // Update state with the combined list
    }
  }, [tdsSectionData]);

  const handleTdsPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      tdsSectionData?.results?.length
    ) {
      setTdsPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      expenseList?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleDateChange = (id) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  const handleFileChange = (event) => {
    setIsChanged(true);
    setFile(Array.from(event.target.files));
  };

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      setPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleSelectChange = (id, selectedValue) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
    setAddData((prevState) => ({
      ...prevState,
      [id]: selectedValue, // Here you're setting the vendor ID
    }));
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/apmodule">
                          <a>AP module</a>
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/apmodule">Invoice List</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Invoice</li>
                    </ul>
                  </div>
                </div>
                {/* /Page Header */}
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit(handleAddSubmit)}>
                      <div className="">
                        <div className="">
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>
                                  Vendor Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="vendor_id"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Select
                                        id="vendor_id"
                                        {...field}
                                        ref={vendorSelectRef}
                                        placeholder="Select a vendor"
                                        showSearch
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        onPopupScroll={handlePopupScroll}
                                        value={field.value} // Ensure the value prop is set to field.value
                                        onChange={(value) => {
                                          handleSelectChange(
                                            "vendor_id",
                                            value,
                                            field.onChange(value)
                                          ); // Your custom handler if needed
                                        }}
                                        dropdownRender={(menu) => (
                                          <>
                                            {menu}
                                            {vendorListLoading && (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  padding: 8,
                                                }}
                                              >
                                                <Spin />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      >
                                        {vendorList
                                          ?.map((option) => ({
                                            value: option.id,
                                            label: option.name,
                                          }))
                                          .map((option) => (
                                            <Option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </Option>
                                          ))}
                                      </Select>
                                      {errors?.vendor_id?.message && (
                                        <p className="text-danger">
                                          {errors?.vendor_id?.message}
                                        </p>
                                      )}
                                      {editError?.vendor_id && (
                                        <p className="text-danger">
                                          {editError?.vendor_id}
                                        </p>
                                      )}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <CustomInput
                                  id="vendor_code"
                                  type="text"
                                  value={addData?.vendor_code}
                                  onChange={handleCommonChange}
                                  placeholder="Vendor Code"
                                  label="Vendor Code"
                                  disabled={true}
                                  apiError={editError?.vendor_code}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <CustomInput
                                  id="vendor_gstin"
                                  type="text"
                                  value={addData?.vendor_gstin}
                                  onChange={handleCommonChange}
                                  placeholder="Vendor GSTIN"
                                  label="Vendor GSTIN"
                                  disabled={true}
                                  apiError={editError?.vendor_gstin}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <Controller
                                  name="invoice_number"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <>
                                      <CustomInput
                                        id="invoice_number"
                                        type="text"
                                        value={value}
                                        onChange={(e) => {
                                          handleChange(e);
                                          onChange(e);
                                        }}
                                        placeholder="Invoice Number"
                                        label="Invoice Number"
                                        errorMsg={
                                          errors?.invoice_number?.message
                                        }
                                        error={errors?.invoice_number}
                                        apiError={editError?.invoice_number}
                                        autoComplete="false"
                                        required={true}
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>
                                  Invoice Date
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="cal-icon cal-icon-info">
                                  <Controller
                                    name="invoice_date"
                                    control={control}
                                    render={({ field }) => (
                                      <div className="cal-icon cal-icon-info">
                                        <DatePicker
                                          {...field}
                                          className="datetimepicker form-control"
                                          selected={field?.value}
                                          onChange={(date) =>
                                            handleDateChange(
                                              "invoice_date",
                                              date,
                                              field?.onChange(date)
                                            )
                                          }
                                          id="invoice_date"
                                          ref={datePickerRef}
                                        />
                                      </div>
                                    )}
                                  />
                                  {errors?.invoice_date?.message && (
                                    <p className="text-danger">
                                      {errors?.invoice_date?.message}
                                    </p>
                                  )}
                                  {editError?.invoice_date && (
                                    <p className="text-danger">
                                      {editError?.invoice_date}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <Controller
                                  name="nature_of_supply"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <CustomInput
                                      id="nature_of_supply"
                                      type="text"
                                      value={value}
                                      onChange={(e) => {
                                        handleChange(e);
                                        onChange(e);
                                      }}
                                      placeholder="Nature of Supply"
                                      label="Nature of Supply"
                                      errorMsg={
                                        errors?.nature_of_supply?.message
                                      }
                                      error={errors?.nature_of_supply}
                                      apiError={editError?.nature_of_supply}
                                      autoComplete="false"
                                      required={true}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label className="form-label">
                                  Invoice File{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="file"
                                  ref={fileInputRef}
                                  multiple
                                  onChange={handleFileChange}
                                />
                                {Array.isArray(file) &&
                                  file.map((el, index) => (
                                    <blockquote key={index}>
                                      <p className="mb-0">{el?.name}</p>
                                    </blockquote>
                                  ))}
                                {errors?.invoice_file && (
                                  <p className="text-danger">
                                    {errors?.invoice_file}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="complex-invoice-table">
                        <div
                          className="ag-theme-alpine"
                          style={{ width: "100%" }}
                        >
                          <h6>Invoice Intent</h6>
                          <div
                            className="tableHscroll"
                            style={{
                              width: "100%",
                              minHeight: "300px",
                              overflowY: "visible",
                            }}
                          >
                            <table className="apedittable">
                              <thead>
                                <tr>
                                  <td align="center">Sl No.</td>
                                  <td>Ledger Name</td>
                                  <td>Description</td>
                                  <td>Unit</td>
                                  <td>Unit Price</td>
                                  <td>Discount</td>
                                  <td>Base Amount</td>
                                  <td>GST Rate</td>
                                  <td>GST Amount</td>
                                  <td>Total</td>
                                  <td>Tds Ledger</td>
                                  <td>Tds Amount</td>
                                  <td>&nbsp;</td>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.map((row, index) => (
                                  <tr key={index}>
                                    <td align="center"> {index + 1}</td>
                                    <td className="customtableSelect">
                                      {renderCell(
                                        row.id,
                                        "posting_ledger",
                                        row?.posting_ledger,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "description",
                                        row.description,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "quantity",
                                        row.quantity,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "rate",
                                        row.rate,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "discount",
                                        row.discount,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "amount",
                                        row.amount,
                                        index,
                                        row
                                      )}
                                    </td>

                                    <td>
                                      {renderCell(
                                        row.id,
                                        "gst_rate",
                                        row.gst_rate,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "gst_amount",
                                        row.gst_amount,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "total",
                                        row.total,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td className="customtableSelect">
                                      {renderCell(
                                        row.id,
                                        "tds_ledger",
                                        row.tds_ledger,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "tds_amount",
                                        row.tds_amount,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td className="tableEditBtns">
                                      <button
                                        className="btn btn-default btn-xs delete"
                                        onClick={(event) =>
                                          handleRemove(event, index)
                                        }
                                      >
                                        <i className="fas fa-trash" />
                                      </button>
                                    </td>
                                  </tr>
                                ))}

                                <tr>
                                  <td colSpan={13}>
                                    <div className="stickyAddBtn">
                                      <button
                                        className="btn btn-greys btn-xs delete"
                                        onClick={handleAddClick}
                                      >
                                        <i className="far fa-add" />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={13}></td>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={10}></td>
                                    <td align="left">
                                      <strong>Base Amount</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_base_amount
                                        ? footerData?.total_base_amount
                                        : 0}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                {footerData?.gst_section &&
                                  Object.keys(footerData?.gst_section)?.map(
                                    (el, index) => {
                                      return (
                                        <tr key={index} className="rowStickty">
                                          <>
                                            <td colSpan={10}></td>
                                            <td align="left">
                                              <strong>{el}</strong>
                                            </td>
                                            <td align="right">
                                              {footerData?.gst_section
                                                ? footerData["gst_section"][el]
                                                : ""}
                                            </td>
                                            <td>&nbsp;</td>
                                          </>
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={10}></td>
                                    <td align="left">
                                      <strong>Total Tax</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_tax
                                        ? footerData?.total_tax
                                        : 0}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={10}></td>
                                    <td align="left">
                                      <strong>Total Amount</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_amount}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={10}></td>
                                    <td align="left">
                                      <strong>Tds Total Amount</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_tds_amount}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={10}></td>
                                    <td align="left">
                                      <strong>Amount Payable</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.amount_payable}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <AddLedgerModal
                            addLedgerData={addLedgerData}
                            apiError={apiError}
                            handleChange={handleLedgerChange}
                            handleModalClose={handleModalClose}
                            handleEditSubmit={handleAddSubmit}
                            loading={postLedgerLoading}
                            handleSelectChange={handleLedgerSelectChange}
                          />
                        </div>
                      </div>

                      {itemsError && error?.data?.items && showErrors && (
                        <>
                          <InvoiceErrorDisplay
                            itemsError={itemsError}
                            onClose={() => setShowErrors(false)}
                          />
                          ;
                        </>
                      )}
                      <div class="row">
                        <div class="col">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!isChanged || loading}
                          >
                            {loading ? <Spin /> : "Save"}
                          </button>
                          &nbsp;
                          <button
                            type="reset"
                            class="btn btn-primary cancel me-2"
                            onClick={() => history.push("/apmodule")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default addInvoice;
