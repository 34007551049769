import React from "react";
import { Spin } from "antd";
import PropTypes from 'prop-types';

export default function DeleteModal({
  id,
  show,
  title,
  deleteData,
  errorMsg,
  handleSubmit,
  deleteLoading,
}) {
  return (
    <div
      className="modal custom-modal fade"
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form-header">
              <h3>{title}</h3>
              <p>
                Are you sure want to delete <b>{deleteData}</b>?
              </p>
            </div>
            {errorMsg && (
              <p className="text-danger" style={{ textAlign: "center" }}>
                {errorMsg}
              </p>
            )}
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <button
                    className="w-100 btn btn-primary paid-continue-btn"
                    onClick={handleSubmit}
                  >
                    {deleteLoading ? <Spin /> : "Delete"}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="reset"
                    data-bs-dismiss="modal"
                    className="w-100 btn btn-primary paid-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DeleteModal.propTypes = {
  id : PropTypes.string.isRequired,
  show :PropTypes.bool.isRequired,
  title : PropTypes.string.isRequired, 
  deleteData : PropTypes.string.isRequired,
  errorMsg : PropTypes.string.isRequired,
  handleSubmit : PropTypes.func.isRequired,
  deleteLoading : PropTypes.bool,
};
