export const ZOHOCRM_CREATE_REQUEST = "ZOHOCRM_CREATE_REQUEST";
export const ZOHOCRM_CREATE_SUCCESS = "ZOHOCRM_CREATE_SUCCESS";
export const ZOHOCRM_CREATE_FAIL = "ZOHOCRM_CREATE_FAIL";
export const ZOHOCRM_CREATE_RESET = "ZOHOCRM_CREATE_RESET"

export const ZOHOCRM_LIST_REQUEST = "ZOHOCRM_LIST_REQUEST";
export const ZOHOCRM_LIST_SUCCESS = "ZOHOCRM_LIST_SUCCESS";
export const ZOHOCRM_LIST_FAIL = "ZOHOCRM_LIST_FAIL";

export const ZOHOAUTH_LIST_REQUEST = "ZOHOAUTH_LIST_REQUEST";
export const ZOHOAUTH_LIST_SUCCESS = "ZOHOAUTH_LIST_SUCCESS";
export const ZOHOAUTH_LIST_FAIL = "ZOHOAUTH_LIST_FAIL";
export const ZOHOAUTH_LIST_RESET = "ZOHOAUTH_LIST_RESET"