export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_SIGNOUT = "USER_SIGNOUT";

export const GET_USER_ACTIVITY_REQUEST = "GET_USER_ACTIVITY_REQUEST";
export const GET_USER_ACTIVITY_SUCCESS = "GET_USER_ACTIVITY_SUCCESS";
export const GET_USER_ACTIVITY_FAIL = "GET_USER_ACTIVITY_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

//otp
export const USER_OTP_REQUEST = "USER_OTP_REQUEST";
export const USER_OTP_SUCCESS = "USER_OTP_SUCCESS";
export const UPDATE_OTP_INFO = "UPDATE_OTP_INFO";
export const USER_OTP_FAIL = "USER_OTP_FAIL";
//company detail
export const USER_COMPANY_REQUEST = "USER_COMPANY_REQUEST";
export const USER_COMPANY_SUCCESS = "USER_COMPANY_SUCCESS";
export const USER_COMPANY_FAIL = "USER_COMPANY_FAIL";
export const USER_COMPANY_RESET = "USER_COMPANY_RESET";

//payment
export const GET_PAYMENT_PLANS_LIST_REQUEST = "GET_PAYMENT_PLANS_LIST_REQUEST";
export const GET_PAYMENT_PLANS_LIST_SUCCESS = "GET_PAYMENT_PLANS_LIST_SUCCESS";
export const GET_PAYMENT_PLANS_LIST_FAIL = "GET_PAYMENT_PLANS_LIST_FAIL";
export const GET_PAYMENT_PLANS_LIST_RESET = "GET_PAYMENT_PLANS_LIST_RESET";

export const GET_TEAM_MEMBER_LIST_REQUEST = "GET_TEAM_MEMBER_LIST_REQUEST";
export const GET_TEAM_MEMBER_LIST_SUCCESS = "GET_TEAM_MEMBER_LIST_SUCCESS";
export const GET_TEAM_MEMBER_LIST_FAIL = "GET_TEAM_MEMBER_LIST_FAIL";

export const TEAM_MEMBER_CREATE_REQUEST = "TEAM_MEMBER_CREATE_REQUEST";
export const TEAM_MEMBER_CREATE_SUCCESS = "TEAM_MEMBER_CREATE_SUCCESS";
export const TEAM_MEMBER_CREATE_FAIL = "TEAM_MEMBER_CREATE_FAIL";
export const TEAM_MEMBER_CREATE_RESET = "TEAM_MEMBER_CREATE_RESET";

export const TEAM_MEMBER_UPDATE_REQUEST = "TEAM_MEMBER_UPDATE_REQUEST";
export const TEAM_MEMBER_UPDATE_SUCCESS = "TEAM_MEMBER_UPDATE_SUCCESS";
export const TEAM_MEMBER_UPDATE_FAIL = "TEAM_MEMBER_UPDATE_FAIL";
export const TEAM_MEMBER_UPDATE_RESET = "TEAM_MEMBER_UPDATE_RESET";

export const TEAM_MEMBER_DELETE_REQUEST = "TEAM_MEMBER_DELETE_REQUEST";
export const TEAM_MEMBER_DELETE_SUCCESS = "TEAM_MEMBER_DELETE_SUCCESS";
export const TEAM_MEMBER_DELETE_FAIL = "TEAM_MEMBER_DELETE_FAIL";
export const TEAM_MEMBER_DELETE_RESET = "TEAM_MEMBER_DELETE_RESET";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";
export const USER_PROFILE_UPDATE_RESET = "USER_PROFILE_UPDATE_RESET";

export const SIDE_MENU_UPDATE = "SIDE_MENU_UPDATE";
