import React from "react";
import { useDispatch } from "react-redux";
import { updateBankDetail } from "../redux/actions/BankModuleAction";
import PropTypes from 'prop-types';
import BankAddEditModal from "./bankAddEditModal";
import { currencyTypes } from "./constant";

const EditBank = ({
  editData,
  bankId,
  modalRef,
  show,
  handleSubmit,
  handleOptionChange,
  handleChange,
  errors,
  editError,
  control,
  selectRefs,
  handleSelectChange,
  handleCheckBoxChange,
  handleModalClose,
  editLoading,
}) => {
  const dispatch = useDispatch();

  const editBankSubmit = (data) => {
    const postData = {
      account_type: editData?.account_type,
      account_code: editData?.account_code,
      account_name: data?.account_name,
      currency: data?.currency ? data?.currency?.value : null,
      bank_name: data?.bank_name,
      description: editData?.description ? editData?.description : "",
      ifsc_code: editData?.ifsc_code ? editData?.ifsc_code : "",
      account_number: editData?.account_number ? editData?.account_number : "",
      last_four_card_digits: editData?.last_four_card_digits
        ? editData?.last_four_card_digits
        : "",
    };
    if (editData?.account_type === "Bank") {
      postData["is_primary"] = editData?.is_primary?editData?.is_primary:false
    }
    dispatch(updateBankDetail(postData, bankId));
  };

  const editCreditCard = (
    <BankAddEditModal
    id={"edit_bank"}
    modalRef={modalRef}
    show={show}
    title={"Edit Bank or Credit Card"}
    handleSubmit={handleSubmit}
    addBankSubmit={editBankSubmit}
    bankData={editData}
    handleOptionChange={handleOptionChange}
    addError={editError}
    control={control}
    errors={errors}
    handleChange={handleChange}
    selectRefs={selectRefs}
    currencyTypes={currencyTypes}
    handleSelectChange={handleSelectChange}
    handleCheckBoxChange={handleCheckBoxChange}
    handleModalClose={handleModalClose}
    loading={editLoading}
    />
  );

  return <>{editCreditCard}</>;
};

EditBank.propTypes = {
  editData : PropTypes.object.isRequired,
  bankId : PropTypes.string.isRequired,
  modalRef : PropTypes.object.isRequired,
  show : PropTypes.bool.isRequired,
  handleSubmit : PropTypes.func.isRequired,
  handleOptionChange : PropTypes.func.isRequired,
  handleChange : PropTypes.func.isRequired,
  errors : PropTypes.object.isRequired,
  editError : PropTypes.object,
  control : PropTypes.object.isRequired,
  selectRefs : PropTypes.object.isRequired,
  currencyTypes : PropTypes.array,
  handleSelectChange : PropTypes.func.isRequired,
  handleCheckBoxChange : PropTypes.func.isRequired,
  handleModalClose : PropTypes.func.isRequired,
  editLoading : PropTypes.bool,
}

export default EditBank;
