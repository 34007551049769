/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";

const PdfViewer = ({ pdfUrl, moduleName }) => {
  const [scale, setScale] = useState(1);

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: moduleName === "apModuleList" ? "320px" : "600px",
          width: moduleName === "apModuleList" ? "370px" : "80%", // Adjust this value to reduce the width
          margin: "0 auto", // Center the div in its container
        }}
      >
        <iframe
          src={pdfUrl}
          style={{
            width: `${100 * scale}%`, // Set width based on scale
            height: "100%", // Full height of the container
            border: "none", // No border
            transform: `scale(${scale})`, // Scale transformation
            transformOrigin: "top left", // Set the origin for scaling
          }}
          title="PDF Viewer"
        />
      </div>
    </div>
  );
};

export default PdfViewer;

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  moduleName: PropTypes.string,
};
